import React from "react";

import PersonForm from "../components/PersonForm";

export default function PersonCreate() {
  return (
    <>
      <PersonForm />
    </>
  );
}
