import { useMutation, useQuery } from "react-query";
import {
  handleBasicGet,
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleSave,
  handleSimpleList,
} from "./handler";
import { Trial, TrialInput } from "../types";
import { isEmpty } from "lodash";
import queryClient from "./queryClient";

const methods = {
  useDetail: (id?: number | string) => {
    return useQuery<Trial>({
      queryKey: ["trial", id?.toString()],
      queryFn: () => handleDetail({ baseUrl: "trials", id }),
      enabled: !!id,
    });
  },
  useListByVarietyIds: (varietyIds: string[]) => {
    return useQuery<Trial[]>({
      queryKey: ["trial_by_variety_ids", varietyIds.join(",")],
      queryFn: () =>
        handleSimpleList({
          url: `trials/by_variety_ids`,
          params: [{ key: "ids", value: varietyIds.join(",") }],
        }),
      enabled: !isEmpty(varietyIds),
    });
  },
  usePrintList: () => {
    return useMutation({
      mutationFn: ({
        searchField,
        filtersHash,
        tagFilters,
        order,
        orderBy,
        personalized,
      }: {
        searchField: string;
        filtersHash: any;
        tagFilters: any;
        order: string;
        orderBy: string;
        personalized: boolean;
      }) =>
        handleBasicPost({
          url: "trials/list",
          input: {
            searchField,
            filtersHash,
            tagFilters,
            order,
            orderBy,
            personalized,
          },
        }),
    });
  },
  useExcelList: () => {
    return useMutation({
      mutationFn: ({
        searchField,
        filtersHash,
        tagFilters,
        personalized,
      }: {
        searchField: string;
        filtersHash: any;
        tagFilters: any;
        personalized: boolean;
      }) =>
        handleBasicPost({
          url: "trials/excel_list",
          input: {
            searchField,
            filtersHash,
            tagFilters,
            personalized,
          },
        }),
    });
  },
  useGeneratePlotMap: ({ trialId }: { trialId?: number | string }) => {
    return useMutation({
      mutationFn: ({ supplierId }: { supplierId?: number }) =>
        handleBasicPost({
          url: `trials/${trialId}/plot_report`,
          input: {
            supplierId,
          },
        }),
    });
  },
  useGenerateEvaluationReport: ({ trialId }: { trialId?: number | string }) => {
    return useMutation({
      mutationFn: ({ supplierId }: { supplierId?: number }) =>
        handleBasicPost({
          url: `trials/${trialId}/evaluation_report`,
          input: {
            supplierId,
          },
        }),
    });
  },
  useGenerateEvaluationReportExcel: ({
    trialId,
  }: {
    trialId?: number | string;
  }) => {
    return useMutation({
      mutationFn: ({ supplierId }: { supplierId?: number }) =>
        handleBasicPost({
          url: `trials/${trialId}/evaluation_report_excel`,
          input: {
            supplierId,
          },
        }),
    });
  },
  useSendEvaluationEmail: ({
    trialId,
    supplierId,
  }: {
    trialId?: number | string;
    supplierId?: number | string;
  }) => {
    return useMutation({
      mutationFn: (peopleIds: number[]) =>
        handleBasicPost({
          url: `trials/${trialId}/send_evaluation_report`,
          input: {
            supplierId,
            people: peopleIds,
          },
        }),
    });
  },
  useSendPlotMapEmail: ({
    trialId,
    supplierId,
  }: {
    trialId?: number | string;
    supplierId?: number | string;
  }) => {
    return useMutation({
      mutationFn: (peopleIds: number[]) =>
        handleBasicPost({
          url: `trials/${trialId}/send_plot_report`,
          input: {
            supplierId,
            people: peopleIds,
          },
        }),
    });
  },
  useReportStatus: ({
    trialId,
    reportId,
  }: {
    trialId: number;
    reportId: number;
  }) => {
    return useQuery({
      queryKey: ["trialReport", trialId.toString(), reportId?.toString()],
      queryFn: () =>
        handleBasicGet({
          url: `trials/${trialId}/report_status`,
          params: [{ key: "report_id", value: reportId }],
        }),
      enabled: !!reportId,
      cacheTime: 0,
    });
  },
  useCreate: () => {
    return useMutation<Trial>({
      mutationFn: () =>
        handleSave({
          baseUrl: "trials",
          input: {},
        }),
      onSuccess: async (trial: Trial) => {
        queryClient.setQueryData(
          ["trials", trial?.id?.toString()],
          (oldData: any) => {
            return trial;
          }
        );
      },
    });
  },
  useSave: (input: TrialInput) => {
    return useMutation<Trial>({
      mutationFn: () =>
        handleSave<Trial>({
          baseUrl: "trials",
          input,
        }),
      onSuccess: async (trial: Trial) => {
        queryClient.setQueryData(
          ["trials", trial?.id?.toString()],
          (oldData: any) => {
            return trial;
          }
        );
      },
    });
  },
  //  ["trial", id?.toString()],
  useDelete: (id?: number | string) => {
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "trials", id }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.invalidateQueries({ queryKey: ["trials", id?.toString()] });
      },
    });
  },
};
export default methods;
