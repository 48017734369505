import { liteClient } from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import AlgoliaTrialList from "../components/AlgoliaTrialList";
import AlgoliaTrialSearchBox from "../components/AlgoliaTrialSearchBox";

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function TrialList() {
  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={`Trial_${process.env.NODE_ENV}`}
      >
        <AlgoliaTrialSearchBox />
        <AlgoliaTrialList />
      </InstantSearch>
    </>
  );
}
