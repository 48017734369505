import React from "react";
import { useParams } from "react-router-dom";
import TrialApi from "../api/Trial.api";
import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import PlotBedApi from "../api/PlotBed.api";
import {
  createPlotBedByLocation,
  createPlotBedLocation,
  getPlotBedNumbers,
  getPlotNumbersByPlotId,
} from "../utils";
import { PlotBed } from "../types";
import PlotGridLayout from "../components/PlotGridLayout";
import LabelIcon from "@mui/icons-material/Label";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import ReportHeader from "../components/trials/ReportHeader";
import TrialVarietyApi from "../api/TrialVariety.api";
import toast from "react-hot-toast";
import ReportStatus from "../components/ReportStatus";
import EmailDrawer from "../components/trials/EmailDrawer";
import {HttpError} from "../types/http-error";

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

export default function TrialPlotMap() {
  const { id } = useParams<{ id: string }>();
  const [reportId, setReportId] = React.useState<number | undefined>(undefined);
  const [openEmailDrawer, setOpenEmailDrawer] = React.useState<boolean>(false);
  const [supplierId, setSupplierId] = React.useState<number | undefined>(
    undefined
  );

  const { data: trial } = TrialApi.useDetail(id);
  const { data: trialVarieties } = TrialVarietyApi.useList(id);
  const { data: plotBeds } = PlotBedApi.useList({
    parentId: id,
    parentType: "trial",
  });
  const { mutateAsync: printPlotMap } = TrialApi.useGeneratePlotMap({
    trialId: id,
  });
  const { mutateAsync: sendPlotMapEmail } = TrialApi.useSendPlotMapEmail({
    trialId: id,
    supplierId,
  });

  const plotBedByLocation: { [key: string]: PlotBed } =
    createPlotBedByLocation(plotBeds);

  if (!trial) {
    return <div>Loading...</div>;
  }

  const handlePrint = async () => {
    try {
      const result = await printPlotMap({
        supplierId,
      });
      setReportId(result.id);
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to print Plot Map");
      }
    }
  };

  const setupPlotGrid = () => {
    const plotNumbers = getPlotBedNumbers({
      plotBeds: plotBedByLocation,
      plotType: trial?.plotType,
      rows: trial?.plotRows,
      cols: trial?.plotCols,
    });
    const rows = [];
    for (let rowIndex = trial?.plotRows - 1; rowIndex >= 0; rowIndex--) {
      const cols = [];
      for (let colIndex = 0; colIndex < trial?.plotCols; colIndex++) {
        const locationId = createPlotBedLocation(rowIndex, colIndex);
        const plotNumber = plotNumbers[locationId];

        cols.push({
          key: locationId,
          rowIndex: rowIndex,
          colIndex: colIndex,
          plotNumber: plotNumber,
        });
      }
      rows.push(cols);
    }
    return rows;
  };

  const rows = setupPlotGrid();

  const plotNumbersByPlotId = getPlotNumbersByPlotId({
    plotBeds: plotBedByLocation,
    plotType: trial?.plotType,
    rows: parseInt(trial?.plotRows?.toString()),
    cols: parseInt(trial?.plotCols?.toString()),
  });

  return (
    <Grid container spacing={2}>
      {trial && (
        <>
          <Grid item xs={6}>
            <Typography variant={"h2"}>Plot Map</Typography>
            <Typography variant={"h4"}>{trial.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "2rem",
              }}
            >
              <Box sx={{ flexShrink: 1 }}>
                <Button
                  onClick={() => setOpenEmailDrawer(true)}
                  variant="contained"
                  color={"primary"}
                  size={"large"}
                >
                  <EmailIcon />
                </Button>
                <EmailDrawer
                  showDrawer={openEmailDrawer}
                  handleClose={() => setOpenEmailDrawer(false)}
                  trial={trial}
                  supplierId={supplierId}
                  sendEmail={sendPlotMapEmail}
                  autoEmailKey={"auto_send_plot_report"}
                />
              </Box>
              <Box sx={{ flexShrink: 1 }}>
                <Button
                  onClick={handlePrint}
                  variant="contained"
                  color={"primary"}
                  size={"large"}
                >
                  <PrintIcon />
                </Button>
              </Box>
            </Box>
          </Grid>
          {reportId && (
            <Grid item xs={12}>
              <ReportStatus reportId={reportId} />
            </Grid>
          )}
          <ReportHeader
            trial={trial}
            supplierId={supplierId}
            setSupplierId={setSupplierId}
          />
          <Divider />
          <Grid item xs={6}>
            <Typography variant={"h4"}>Varieties</Typography>
            {trialVarieties &&
              trialVarieties
                .filter(
                  trialVariety =>
                    !supplierId || trialVariety.variety.companyId === supplierId
                )
                .sort(
                  (a, b) =>
                    plotNumbersByPlotId[a.id] - plotNumbersByPlotId[b.id]
                )
                .map(trialVariety => (
                  <Box sx={{ my: 1 }} key={trialVariety.id}>
                    <Chip
                      variant={"filled"}
                      label={`${plotNumbersByPlotId[trialVariety.id]} ${trialVariety.variety.name}`}
                      avatar={<LabelIcon />}
                    />
                  </Box>
                ))}
          </Grid>
          <Grid item xs={6}>
            <PlotGridLayout
              rows={rows}
              selectedPlotBeds={{}}
              selectPlotBed={(a, b) => {}}
            />
          </Grid>
          <Grid item xs={12}>
            <APIProvider apiKey={googleMapsApiKey} libraries={["marker"]}>
              {trial?.lat && trial?.lng && (
                <Map
                  style={{ width: "auto", height: "30rem" }}
                  center={{
                    lat: parseFloat(trial?.lat),
                    lng: parseFloat(trial?.lng),
                  }}
                  zoom={trial?.zoom}
                  gestureHandling={"greedy"}
                  disableDefaultUI={false}
                  mapTypeId={"satellite"} //terrain, hybrid, roadmap, satellite
                >
                  {/* Child components, such as markers, info windows, etc. */}
                  <Marker
                    position={{
                      lat: parseFloat(trial?.lat),
                      lng: parseFloat(trial?.lng),
                    }}
                    clickable={true}
                    onClick={() => alert("marker was clicked!")}
                    title={"clickable google.maps.Marker"}
                  />
                </Map>
              )}
            </APIProvider>
          </Grid>
        </>
      )}
    </Grid>
  );
}
