import { useMutation, useQuery } from "react-query";
import { Subcriterium, SubcriteriumInput } from "../types";
import { handleDelete, handleSave, handleSimpleList } from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: (criteriumId: number | string) => {
    return useQuery<Subcriterium[]>({
      queryKey: ["subcriteria", criteriumId?.toString()],
      queryFn: () =>
        handleSimpleList<Subcriterium>({
          url: `criteria/${criteriumId}/subcriteria`,
        }),
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (subcriterium: SubcriteriumInput) =>
        handleSave<Subcriterium>({
          baseUrl: `criteria/${subcriterium.criteriumId}/subcriteria`,
          input: subcriterium,
        }),
      onSuccess: async (data: Subcriterium) => {
        queryClient.setQueryData(
          ["subcriteria", data.criteriumId.toString()],
          (oldData: Subcriterium[] | undefined) => {
            if (oldData) {
              oldData.filter(
                subcriterium =>
                  subcriterium.id.toString() === data.id.toString()
              );
              return [...oldData, data];
            }
            return [data];
          }
        );
      },
    });
  },
  useDelete: () => {
    return useMutation({
      mutationFn: (subcriterium: Subcriterium) =>
        handleDelete<{ id: string; criteriumId: string }>({
          baseUrl: `criteria/${subcriterium.criteriumId}/subcriteria`,
          id: subcriterium.id,
        }),
      onSuccess: async ({
        id,
        criteriumId,
      }: {
        id: string;
        criteriumId: string;
      }) => {
        queryClient.setQueryData(
          ["subcriteria", criteriumId?.toString()],
          (oldData: Subcriterium[] | undefined) => {
            return (
              oldData?.filter(
                subcriterium => subcriterium.id !== parseInt(id)
              ) || []
            );
          }
        );
      },
    });
  },
};
export default methods;
