import React from "react";
import PrintIcon from "@mui/icons-material/Print";
import ArticleIcon from "@mui/icons-material/Article";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
import { Configure, useSearchBox } from "react-instantsearch"; // UseSearchBoxProps,
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { buildFilters, buildTagFilters, buildFilterHash } from "../utils";
import PageHeader from "../ui/PageHeader";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import toast from "react-hot-toast";
import TrialApi from "../api/Trial.api";
import { useNavigate } from "react-router-dom";
import ReportStatus from "./ReportStatus";
import UserApi from "../api/User.api";
import { useSession } from "../contexts/Session.context";
import PersonalViewApi from "../api/PersonalView.api";

// UseSearchBoxProps
export default function AlgoliaTrialSearchBox(props: any) {
  const searchBoxApi = useSearchBox(props);

  const { session } = useSession();
  const { data: user } = UserApi.useDetail(session?.userId);
  const { data: personalViews } = PersonalViewApi.useList(session?.userId);

  const navigate = useNavigate();

  const { mutateAsync: createTrial } = TrialApi.useCreate();
  const { mutateAsync: printTrials } = TrialApi.usePrintList();
  const { mutateAsync: excelTrials } = TrialApi.useExcelList();

  const [reportId, setReportId] = React.useState<number | undefined>(undefined);
  const [excludeYears, setExcludeYears] = React.useState(false);
  const [mexico, setMexico] = React.useState(true);
  const [archived, setArchived] = React.useState(false);
  const [wetDateStart, setWetDateStart] = React.useState<Dayjs | null>(null);
  const [wetDateEnd, setWetDateEnd] = React.useState<Dayjs | null>(null);
  const [harvestDateStart, setHarvestDateStart] = React.useState<Dayjs | null>(
    null
  );
  const [harvestDateEnd, setHarvestDateEnd] = React.useState<Dayjs | null>(
    null
  );

  const handleCreate = async () => {
    try {
      const trial = await createTrial();
      navigate(`/trials/${trial.id}`);
    } catch (e) {
      console.error(e);
      toast.error("Failed to create trial");
    }
  };

  const filters = buildFilters({
    archived,
    mexico,
    excludeYears,
    wetDateStart,
    wetDateEnd,
    harvestDateStart,
    harvestDateEnd,
  });

  const filtersHash = buildFilterHash({
    archived,
    mexico,
    excludeYears,
    wetDateStart,
    wetDateEnd,
    harvestDateStart,
    harvestDateEnd,
  });

  const tagFilters = buildTagFilters({
    personalized: props.personalized,
    personalViews: personalViews,
  });

  const handleExcel = async () => {
    try {
      const result = await excelTrials({
        personalized: props.personalized,
        searchField: searchBoxApi.query,
        filtersHash,
        tagFilters,
      });
      console.log("handleExcel", result);
      setReportId(result.id);
    } catch (e) {
      console.error(e);
      toast.error("Failed to create excel");
    }
  };

  const handlePrint = async () => {
    try {
      const result = await printTrials({
        personalized: props.personalized,
        searchField: searchBoxApi.query,
        filtersHash,
        tagFilters,
        order: "desc",
        orderBy: "harvest_date",
      });
      console.log("handlePrint", result);
      setReportId(result.id);
      // { id: number, status: string }
      // save this report number and check the proper location until data is fed back
    } catch (e) {
      console.error(e);
      toast.error("Failed to print Trials");
    }
  };

  return (
    <>
      <PageHeader
        title={
          props.personalized
            ? `${user && user.firstName} ${user && user.lastName} Trials`
            : "Trials"
        }
        half={6}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={handlePrint}
          >
            <PrintIcon />
          </Button>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={handleExcel}
          >
            <ArticleIcon />
          </Button>
          <IndexHeaderButton handleCreate={handleCreate} />
        </Box>
      </PageHeader>
      {reportId && <ReportStatus reportId={reportId} />}
      <Configure analytics={true} filters={filters} tagFilters={tagFilters} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <TextField
            id={"input-with-icon-trial-combine"}
            value={searchBoxApi.query}
            onChange={e => searchBoxApi.refine(e.target.value)}
            autoFocus={true}
            fullWidth
            type={"search"}
            variant={"standard"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{ flex: 1, display: "flex", flexDirection: "row", gap: "1rem" }}
        >
          <Box sx={{ flexShrink: "initial" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={excludeYears}
                    onChange={e => setExcludeYears(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Exclude Years"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={mexico}
                    onChange={e => setMexico(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Mexico"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={archived}
                    onChange={e => setArchived(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Archived"
              />
            </FormGroup>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
              }}
            >
              <DatePicker
                label={"Start Wet Date"}
                value={wetDateStart}
                onChange={newValue => setWetDateStart(newValue)}
                sx={{ width: "100%" }}
              />
              <DatePicker
                label={"End Wet Date"}
                sx={{ width: "100%" }}
                value={wetDateEnd}
                onChange={newValue => setWetDateEnd(newValue)}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
              }}
            >
              <DatePicker
                label={"Start Harvest Date"}
                sx={{ width: "100%" }}
                value={harvestDateStart}
                onChange={newValue => setHarvestDateStart(newValue)}
              />
              <DatePicker
                label={"End Harvest Date"}
                sx={{ width: "100%" }}
                value={harvestDateEnd}
                onChange={newValue => setHarvestDateEnd(newValue)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
