import { useMutation, useQuery } from "react-query";
import { EvaluableType, Evaluation, EvaluationInput, Param } from "../types";
import { handleSave, handleSimpleList } from "./handler";
import queryClient from "./queryClient";
import { isEmpty, unionBy } from "lodash";

const methods = {
  useList: ({
    evaluableType,
    evaluableId,
  }: {
    evaluableType: EvaluableType;
    evaluableId: number;
  }) => {
    const params: Param[] = [];
    params.push({
      key: "evaluable_id",
      value: evaluableId,
    });
    params.push({
      key: "evaluable_type",
      value: evaluableType,
    });
    return useQuery<Evaluation[]>({
      queryKey: ["evaluations", evaluableType, evaluableId],
      queryFn: () =>
        handleSimpleList<Evaluation>({ url: "evaluations", params }),
    });
  },
  useListByVarietyIds: (varietyIds: string[]) => {
    return useQuery<Evaluation[]>({
      queryKey: ["evaluation_by_variety_ids", varietyIds.join(",")],
      queryFn: () =>
        handleSimpleList({
          url: `evaluations/by_variety_ids`,
          params: [{ key: "ids", value: varietyIds.join(",") }],
        }),
      enabled: !isEmpty(varietyIds),
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: ({
        evaluationInput,
        evaluableId,
        evaluableType,
      }: {
        evaluationInput: EvaluationInput;
        evaluableType: EvaluableType;
        evaluableId: number;
      }) =>
        handleSave<Evaluation[]>({
          baseUrl: "evaluations",
          input: { evaluations: [evaluationInput], evaluableType, evaluableId },
        }),
      onSuccess: async (data: Evaluation[]) => {
        console.log("evaluationData", data);
        const evaluableType = data[0].evaluableType;
        const evaluableId = data[0].evaluableId;
        queryClient.setQueryData(
          ["evaluations", evaluableType, evaluableId.toString()],
          (oldData: Evaluation[] | undefined) => {
            if (oldData) {
              return unionBy(oldData, data, "id");
            } else {
              return data;
            }
          }
        );
      },
    });
  },
};
export default methods;
