import { handleDelete, handleSave, handleSimpleList } from "./handler";
import { NurseryVariety, NurseryVarietyInput, PlotBed } from "../types";
import { useMutation, useQuery } from "react-query";
import queryClient from "./queryClient";

const methods = {
  useList: (nurseryId?: number | string) => {
    return useQuery<NurseryVariety[]>({
      queryKey: ["nursery_varieties", nurseryId?.toString()],
      queryFn: () =>
        handleSimpleList({ url: `nurseries/${nurseryId}/nurse_varieties` }),
      enabled: !!nurseryId,
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (nurseryVarietyInput: NurseryVarietyInput) =>
        handleSave<NurseryVariety>({
          baseUrl: `nurseries/${nurseryVarietyInput.nurseryId}/nurse_varieties`,
          input: {
            ...nurseryVarietyInput,
            nurseriesId: nurseryVarietyInput.nurseryId,
          },
        }),
      onSuccess: async (data: NurseryVariety) => {
        queryClient.setQueryData(
          ["nursery_varieties", data.nurseryId.toString()],
          (oldData: NurseryVariety[] | undefined) => {
            return oldData ? [...oldData, data] : [data];
          }
        );
        queryClient.setQueryData(
          ["plot_beds", "nursery", data.nurseryId.toString()],
          (oldData: PlotBed[] | undefined) => {
            return oldData ? [...oldData, ...data.plotBeds] : data.plotBeds;
          }
        );
      },
    });
  },
  useDelete: () => {
    return useMutation({
      mutationFn: (nurseryVariety: NurseryVariety) =>
        handleDelete<{ id: string; nurseryId: string }>({
          baseUrl: `nurseries/${nurseryVariety.nurseryId}/nurse_varieties`,
          id: nurseryVariety.id,
        }),
      onSuccess: async ({
        id,
        nurseryId,
      }: {
        id: string;
        nurseryId: string;
      }) => {
        queryClient.setQueryData(
          ["nursery_varieties", nurseryId],
          (oldData: NurseryVariety[] | undefined) => {
            return (
              oldData?.filter(
                nurseryVariety =>
                  nurseryVariety.id?.toString() !== id?.toString()
              ) || []
            );
          }
        );
        queryClient.setQueryData(
          ["plot_beds", "nursery", nurseryId],
          (oldData: PlotBed[] | undefined) => {
            return (
              oldData?.filter(
                plotBed => plotBed.plotsId?.toString() !== id?.toString()
              ) || []
            );
          }
        );
      },
    });
  },
};
export default methods;
