import React from "react";
import {
  Area,
  Commodity,
  CulturalPractice,
  HarvestDateType,
  Region,
  StatisticRecord,
  Variety,
} from "../types";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CommoditySelect from "../components/CommoditySelect";
import { Dayjs } from "dayjs";
import RegionSelect from "../components/RegionSelect";
import VarietySelect from "../components/VarietySelect";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AreaSelect from "../components/AreaSelect";
import StatisticsApi from "../api/Statistics.api";
import toast from "react-hot-toast";
import DaysToHarvestTable from "../components/DaysToHarvestTable";

export default function Statistics() {
  const [dateType, setDateType] = React.useState<HarvestDateType>("wet_date");
  const [commodity, setCommodity] = React.useState<Commodity | undefined>(
    undefined
  );
  const [region, setRegion] = React.useState<Region | undefined>(undefined);
  const [area, setArea] = React.useState<Area | undefined>(undefined);
  const [variety, setVariety] = React.useState<Variety | undefined>(undefined);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [culturalPractice, setCulturalPractice] =
    React.useState<CulturalPractice>("conventional");

  const [data, setData] = React.useState<StatisticRecord[]>([]);
  const { mutateAsync: fetchResults } = StatisticsApi.useCommodity();

  const handleSeach = async () => {
    if (!commodity) {
      toast.error("No commodity selected");
      return;
    }
    if (!region) {
      toast.error("No region selected");
      return;
    }
    if (!startDate) {
      toast.error("No start date selected");
      return;
    }
    if (!endDate) {
      toast.error("No end date selected");
      return;
    }
    const results = await fetchResults({
      dateType,
      commodityId: commodity?.id,
      regionId: region?.id,
      areaId: area?.id,
      varietyId: variety?.id,
      startDate: startDate,
      endDate: endDate,
      culturalPractice,
    });
    setData(results);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
      <Box>
        <Typography variant="h2">Days to Harvest</Typography>
      </Box>
      <Box
        sx={{
          maxWidth: "50rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "4rem" }}>
          <Box sx={{ flexShrink: 1 }}>
            <RadioGroup
              aria-labelledby="label-date-type-radio-group"
              name="date-type-radio-group"
              value={dateType}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDateType(event.target.value as HarvestDateType);
              }}
            >
              <FormControlLabel
                labelPlacement="start"
                value="wet_date"
                control={<Radio />}
                label="Wet Date"
              />
              <FormControlLabel
                labelPlacement="start"
                value="transplant_date"
                control={<Radio />}
                label="Transplant Date"
              />
            </RadioGroup>
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommoditySelect
              commodity={commodity}
              setCommodity={setCommodity}
              label="Commodity"
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Box sx={{ flex: 1 }}>
            <RegionSelect
              region={region}
              setRegion={setRegion}
              label="Region"
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <AreaSelect area={area} setArea={setArea} label={"Area"} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <VarietySelect
              variety={variety}
              setVariety={setVariety}
              label="Variety"
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Box sx={{ flex: 1 }}>
            <DatePicker
              label={"Start Date"}
              sx={{ width: "100%" }}
              value={startDate}
              onChange={newValue => setStartDate(newValue)}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <DatePicker
              label={"End Date"}
              sx={{ width: "100%" }}
              value={endDate}
              onChange={newValue => setEndDate(newValue)}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <RadioGroup
              aria-labelledby="label-cultural-practice-radio-group"
              name="cultural-practice-radio-group"
              value={culturalPractice}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCulturalPractice(event.target.value as CulturalPractice);
              }}
              sx={{ pl: "3rem" }}
            >
              <FormControlLabel
                labelPlacement="end"
                value="conventional"
                control={<Radio />}
                label="Conventional"
              />
              <FormControlLabel
                labelPlacement="end"
                value="organic"
                control={<Radio />}
                label="Organic"
              />
            </RadioGroup>
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSeach}
          >
            Search
          </Button>
        </Box>
      </Box>

      <Box>
        {data.length > 0 && startDate && endDate ? (
          <DaysToHarvestTable
            records={data}
            dateType={dateType}
            startDate={startDate}
            endDate={endDate}
          />
        ) : (
          <Typography variant="h3">
            Not enough data for chosen conditions
          </Typography>
        )}
      </Box>
    </Box>
  );
}
