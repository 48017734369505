import { Autocomplete, TextField } from "@mui/material";
import { Region } from "../types";
import RegionApi from "../api/Region.api";
import React from "react";

type Option = {
  label: string;
  value: Region;
};

export default function RegionMultiSelect({
  regions,
  setRegions,
  label,
}: {
  regions?: Region[];
  setRegions: (arg: Region[]) => void;
  label: string;
}) {
  const [value, setValue] = React.useState<Option[]>(
    regions
      ? regions.map(region => ({ value: region, label: region.name }))
      : []
  );
  const [inputValue, setInputValue] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const { data, isFetching } = RegionApi.useList({ query: inputValue });

  const options: Option[] = data
    ? data.data.map(region => ({ label: region.name, value: region }))
    : [];
  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      autoHighlight
      loading={isFetching}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event: any, newValue: Option[]) => {
        setValue(newValue);
        setRegions(newValue.map(option => option.value) as Region[]);
      }}
      filterOptions={(options, params) => {
        const filtered = options.filter(option => {
          if (regions) {
            return !regions.map(region => region.id).includes(option.value.id);
          } else {
            return true;
          }
        });

        return filtered;
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={params => (
        <TextField variant="standard" {...params} label={label} />
      )}
    />
  );
}
