import React from "react";
import { useParams } from "react-router-dom";

import CommodityForm from "../components/CommodityForm";
import { CommodityProvider } from "../contexts/Commodity.context";

export default function CommodityShow() {
  const { id } = useParams();
  return (
    <CommodityProvider id={id}>
      <CommodityForm />
    </CommodityProvider>
  );
}
