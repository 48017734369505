import React from "react";

import UserForm from "../components/UserForm";

export default function ProfileCreate() {
  return (
    <>
      <UserForm />
    </>
  );
}
