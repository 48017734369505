import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import Loading from "./components/Loading";
import { useSession } from "./contexts/Session.context";
import ErrorPage from "./error-page";
import AreaCreate from "./routes/AreaCreate";
import AreaList from "./routes/AreaList";
import AreaShow from "./routes/AreaShow";
import CommodityList from "./routes/CommodityList";
import CommodityShow from "./routes/CommodityShow";
import CompanyList from "./routes/CompanyList";
import CompanyShow from "./routes/CompanyShow";
import CompanyCreate from "./routes/CompanyCreate";
import DiseaseList from "./routes/DiseaseList";
import DiseaseShow from "./routes/DiseaseShow";
import ForgotPassword from "./routes/ForgotPassword";
import NurseryList from "./routes/NurseryList";
import NurseryShow from "./routes/NurseryShow";
import PasswordReset from "./routes/PasswordReset";
import PersonList from "./routes/PersonList";
import PersonCreate from "./routes/PersonCreate";
import PersonShow from "./routes/PersonShow";
import PersonalizedTrials from "./routes/PersonalizedTrials";
import UserList from "./routes/UserList";
import UserShow from "./routes/UserShow";
import UserCreate from "./routes/UserCreate";
import RanchCreate from "./routes/RanchCreate";
import RanchList from "./routes/RanchList";
import RanchShow from "./routes/RanchShow";
import RegionCreate from "./routes/RegionCreate";
import RegionList from "./routes/RegionList";
import RegionShow from "./routes/RegionShow";
import Reports from "./routes/Reports";
import ReportsCompare from "./routes/ReportsCompare";
import Root from "./routes/root";
import SignIn from "./routes/SignIn";
import Statistics from "./routes/Statistics";
import VarietyCombine from "./routes/VarietyCombine";
import VarietyList from "./routes/VarietyList";
import VarietyShow from "./routes/VarietyShow";
import TrialList from "./routes/TrialList";
import TrialShow from "./routes/TrialShow";
import TrialPlotMap from "./routes/TrialPlotMap";
import TrialEvaluationReport from "./routes/TrialEvaluationReport";
import ReportsSingle from "./routes/ReportsSingle";

const router = createBrowserRouter([
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/password_reset/:token",
    element: <PasswordReset />,
  },
  {
    path: "/forgot_password",
    element: <ForgotPassword />,
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Root />
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <PersonalizedTrials />,
      },
      {
        path: "areas",
        element: <AreaList />,
      },
      {
        path: "areas/create",
        element: <AreaCreate />,
      },
      {
        path: "areas/:id",
        element: <AreaShow />,
      },
      {
        path: "commodities",
        element: <CommodityList />,
      },
      {
        path: "commodities/:id",
        element: <CommodityShow />,
      },
      {
        path: "companies",
        element: <CompanyList />,
      },
      {
        path: "companies/create",
        element: <CompanyCreate />,
      },
      {
        path: "companies/:id",
        element: <CompanyShow />,
      },
      {
        path: "diseases",
        element: <DiseaseList />,
      },
      {
        path: "diseases/:id",
        element: <DiseaseShow />,
      },
      {
        path: "nurseries",
        element: <NurseryList />,
      },
      {
        path: "nurseries/:id",
        element: <NurseryShow />,
      },
      {
        path: "people",
        element: <PersonList />,
      },
      {
        path: "people/create",
        element: <PersonCreate />,
      },
      {
        path: "people/:id",
        element: <PersonShow />,
      },
      {
        path: "users",
        element: <UserList />,
      },
      {
        path: "users/create",
        element: <UserCreate />,
      },
      {
        path: "users/:id",
        element: <UserShow />,
      },
      {
        path: "ranches",
        element: <RanchList />,
      },
      {
        path: "ranches/create",
        element: <RanchCreate />,
      },
      {
        path: "ranches/:id",
        element: <RanchShow />,
      },
      {
        path: "regions",
        element: <RegionList />,
      },
      {
        path: "regions/create",
        element: <RegionCreate />,
      },
      {
        path: "regions/:id",
        element: <RegionShow />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "reports/single/:varietyId",
        element: <ReportsSingle />,
      },
      {
        path: "reports/compare",
        element: <ReportsCompare />,
      },
      {
        path: "statistics",
        element: <Statistics />,
      },
      {
        path: "trials",
        element: <TrialList />,
      },
      {
        path: "trials/:id",
        element: <TrialShow />,
      },
      {
        path: "trials/plot_report/:id",
        element: <TrialPlotMap />,
      },
      {
        path: "trials/evaluation_report/:id",
        element: <TrialEvaluationReport />,
      },
      {
        path: "varieties",
        element: <VarietyList />,
      },
      {
        path: "varieties/:id/combine",
        element: <VarietyCombine />,
      },
      {
        path: "varieties/:id",
        element: <VarietyShow />,
      },
    ],
  },
]);

function PrivateRoute({ children }: { children: JSX.Element }) {
  const { session, loadedSessionStorage } = useSession();

  if (!loadedSessionStorage) {
    return <Loading />;
  }

  if (!session) {
    return <Navigate to="/signin" replace />;
  }
  return children;
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
