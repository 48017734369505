import { useMutation, useQuery } from "react-query";
import { Criterium, CriteriumInput } from "../types";
import { handleDelete, handleSave, handleSimpleList } from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: (commodityId?: number | string) => {
    return useQuery<Criterium[]>({
      enabled: !!commodityId,
      queryKey: ["criteria", commodityId?.toString()],
      queryFn: () =>
        handleSimpleList<Criterium>({
          url: `commodities/${commodityId}/criteria`,
        }),
    });
  },
  useCreate: (commodityId?: number | string) => {
    return useMutation({
      mutationFn: (input: CriteriumInput) =>
        handleSave<Criterium>({
          baseUrl: `commodities/${commodityId}/criteria`,
          input,
        }),
      onSuccess: async (criterium: Criterium) => {
        queryClient.setQueryData(
          ["criteria", criterium.commodityId.toString()],
          (oldData: any) => {
            if (oldData && Array.isArray(oldData)) {
              return [...oldData, criterium];
            } else {
              return [criterium];
            }
          }
        );
      },
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (criterium: Criterium) =>
        handleSave<Criterium>({
          baseUrl: `commodities/${criterium.commodityId}/criteria`,
          input: criterium,
        }),
      onSuccess: async (criterium: Criterium) => {
        queryClient.setQueryData(
          ["criteria", criterium.commodityId.toString()],
          (oldData: any) => {
            const newData = oldData.filter(
              (c: Criterium) => c.id.toString() !== criterium.id.toString()
            );
            return [...newData, criterium];
          }
        );
      },
    });
  },
  useDelete: ({ commodityId }: { commodityId?: number | string }) => {
    return useMutation({
      mutationFn: (id: number) =>
        handleDelete<{ id: number }>({
          baseUrl: `commodities/${commodityId}/criteria`,
          id,
        }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.setQueryData(
          ["criteria", commodityId?.toString()],
          (oldData: any) => {
            if (oldData && Array.isArray(oldData)) {
              return oldData.filter(
                criterium => criterium.id.toString() !== id.toString()
              );
            }
            return oldData;
          }
        );
      },
    });
  },
};

export default methods;
