import React from "react";
import { useNavigate } from "react-router-dom";

import IndexHeaderButton from "../ui/IndexHeaderButton";
import PageHeader from "../ui/PageHeader";
import { liteClient } from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import AlgoliaSearchBox from "../components/AlgoliaSearchBox";
import AlgoliaVarietyList from "../components/AlgoliaVarietyList";
import VarietyApi from "../api/Variety.api";
import { HttpError } from "../types/http-error";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Company, VarietyStatus } from "../types";
import CompanySelect from "../components/CompanySelect";
import { SelectChangeEvent } from "@mui/material/Select";

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function VarietyList() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");
  const [company, setCompany] = React.useState<Company | undefined>();
  const [status, setStatus] = React.useState<VarietyStatus | undefined>(
    undefined
  );

  const { mutateAsync: createVariety, isLoading } = VarietyApi.useSave({
    name,
    companyId: company?.id,
    status,
  });

  const handleCreate = async () => {
    try {
      const result = await createVariety();
      navigate(`/varieties/${result.id}`);
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Variety");
      }
    }
  };

  return (
    <>
      <PageHeader title={"Varieties"} half={6}>
        <React.Fragment>
          <IndexHeaderButton handleCreate={() => setOpenModal(true)} />
          <Modal open={openModal} onClose={() => setOpenModal(false)}>
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Typography variant={"h6"}>Create Variety</Typography>
              <TextField
                variant={"outlined"}
                label={"Name"}
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value);
                }}
                fullWidth
              />
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Status</InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  value={status}
                  onChange={(event: SelectChangeEvent) =>
                    setStatus(event.target.value as VarietyStatus)
                  }
                  label={"Status"}
                >
                  <MenuItem value={undefined}>None</MenuItem>
                  <MenuItem value={"screening"}>Screening</MenuItem>
                  <MenuItem value={"product_development"}>
                    Product Development
                  </MenuItem>
                  <MenuItem value={"sale_trials"}>Sale Trials</MenuItem>
                  <MenuItem value={"commercial"}>Commercial</MenuItem>
                  <MenuItem value={"drop"}>Drop</MenuItem>
                </Select>
              </FormControl>
              <CompanySelect
                company={company}
                setCompany={setCompany}
                label="Supplier"
              />
              <Button
                disabled={isLoading}
                onClick={handleCreate}
                fullWidth
                variant={"contained"}
                color={"primary"}
              >
                Create
              </Button>
            </Box>
          </Modal>
        </React.Fragment>
      </PageHeader>
      <InstantSearch
        searchClient={searchClient}
        indexName={`Variety_${process.env.NODE_ENV}`}
      >
        <AlgoliaSearchBox />
        <AlgoliaVarietyList />
      </InstantSearch>
    </>
  );
}
