import ky from "ky";
import { isEmpty, isNil } from "lodash";
import { jwtDecode } from "jwt-decode";
import { HttpError } from "../types/http-error";

const client = ky.extend({
  retry: 0,
  prefixUrl: process.env.REACT_APP_API_ENDPOINT,
  hooks: {
    beforeRequest: [
      request => {
        const data = window.localStorage.getItem(
          process.env.REACT_APP_PROFILE_LOCAL_STORAGE!
        );
        if (!(isNil(data) || isEmpty(data))) {
          const sessionData = JSON.parse(data) || {};
          const currentTime = Date.now() / 1000;
          const jwtToken = sessionData["idToken"];
          const decodedToken = jwtDecode(jwtToken);
          if (decodedToken?.exp && decodedToken?.exp > currentTime) {
            request.headers.set("Authorization", `Bearer ${jwtToken}`);
          } else {
            window.localStorage.setItem(
              process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
              ""
            );
            window.location.reload();
          }
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response && response.status === 401) {
          const errorJson = await response.json();
          // @ts-ignore
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status === 413) {
          const errorJson = await response.json();
          // @ts-ignore
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status === 422) {
          const errorJson = await response.json();
          // @ts-ignore
          if (errorJson["message"]) {
            // @ts-ignore
            throw new HttpError(response.status, errorJson["message"]);
          }
          // @ts-ignore
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status >= 400) {
          const errorJson = await response.json();
          // @ts-ignore
          throw new HttpError(response.status, errorJson["errors"]);
        }
      },
    ],
  },
});

export default client;
