import { Autocomplete, TextField } from "@mui/material";
import { Region } from "../types";
import React from "react";
import RegionApi from "../api/Region.api";

type Option = {
  label: string;
  value: Region;
};

export default function RegionSelect({
  region,
  setRegion,
  label,
}: {
  region?: Region;
  setRegion: (arg: Region) => void;
  label: string;
}) {
  const [value, setValue] = React.useState<Option | null>(
    region ? { label: region.name, value: region } : null
  );
  const [inputValue, setInputValue] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const { data, isFetching } = RegionApi.useList({ query: inputValue });

  const options: Option[] = data
    ? data.data.map(region => ({ label: region.name, value: region }))
    : [];
  return (
    <Autocomplete
      autoHighlight
      loading={isFetching}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event: any, newValue: Option | null) => {
        setValue(newValue);
        setRegion(newValue?.value as Region);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={params => <TextField {...params} label={label} />}
    />
  );
}
