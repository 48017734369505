import React from "react";
import CompanyApi from "../api/Company.api";
import { Company } from "../types";
import { Autocomplete, TextField } from "@mui/material";

type Option = {
  label: string;
  value: Company;
};

export default function AssociatedCompanies({
  selectedCompanies,
  setSelectedCompanies,
}: {
  selectedCompanies: Company[];
  setSelectedCompanies: (arg: any[]) => void;
}) {
  const [inputValue, setInputValue] = React.useState<string>("");
  const [value, setValue] = React.useState<Option[]>(
    selectedCompanies.map(company => ({
      label: company.name,
      value: company,
    })) || []
  );
  const { data } = CompanyApi.useList({ query: inputValue });
  const options = data
    ? data.data.map(company => ({ label: company.name, value: company }))
    : [];

  return (
    <Autocomplete
      autoHighlight
      multiple
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option: Option, value: Option) => {
        return option.value.id === value.value.id;
      }}
      options={options}
      filterOptions={(options: Option[]) =>
        options.filter(
          option => !value.find(v => v.value.id === option.value.id)
        )
      }
      value={value}
      onChange={(event: any, newValues: Option[]) => {
        setValue(newValues);
        setSelectedCompanies(newValues.map(option => option.value));
      }}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          label="Select Companies"
          variant="outlined"
          placeholder={"Companies"}
        />
      )}
    />
  );
}
