import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { reportTooltip, reportCreateLayers, getAllColors } from "../../utils";
import dayjs from "dayjs";
import React from "react";
import { useReportCompareContext } from "../../contexts/ReportCompare.context";
import { FieldVariety, TrialVariety } from "../../types";

export default function ScatterPlot() {
  const { mainVarieties, varieties, filteredCombinedVarieties } =
    useReportCompareContext();

  const createPoint = (record: TrialVariety | FieldVariety) => {
    if (record.maturityDate && record.score) {
      const maturityDate = dayjs(record.maturityDate);
      return {
        x: maturityDate.format("M-D"),
        y: record.score,
      };
    }
    return null;
  };

  const filterTrialAndFieldVarieties = () => {
    if (!(filteredCombinedVarieties && mainVarieties)) {
      return [];
    }
    return filteredCombinedVarieties.filter(column =>
      mainVarieties[column.typeVariety?.variety?.id] ? true : false
    );
  };

  const compileMainVarietiesSubVarieties = () => {
    const subVarietiesByMainVariety: { [key: number]: string } = {};
    mainVarieties &&
      Object.values(mainVarieties).forEach(variety => {
        subVarietiesByMainVariety[variety.id] = variety.name;
        varieties &&
          Object.values(varieties).forEach(v => {
            if (v.mainVarietyId === variety.id) {
              subVarietiesByMainVariety[v.id] = variety.name;
            }
          });
      });
    return subVarietiesByMainVariety;
  };

  const seperateDataByVariety = () => {
    const subVarietiesByMainVariety = compileMainVarietiesSubVarieties();
    const seperatedVarieties: { [key: string]: any[] } = {};
    const columns = filterTrialAndFieldVarieties();
    columns
      .filter(column =>
        subVarietiesByMainVariety[column.typeVariety.variety.id] ? true : false
      )
      .forEach(column => {
        const mainVarietyKey =
          subVarietiesByMainVariety[column.typeVariety.varietyId];
        if (!seperatedVarieties[mainVarietyKey]) {
          seperatedVarieties[mainVarietyKey] = [];
        }
        const point = createPoint(column.typeVariety);
        if (point) {
          seperatedVarieties[mainVarietyKey].push(point);
        }
      });
    return seperatedVarieties;
  };

  const compileData = () => {
    const varietyData = seperateDataByVariety();
    return Object.keys(varietyData).map(varietyName => {
      const trialVarietyPoints = varietyData[varietyName];
      return {
        id: varietyName,
        data: trialVarietyPoints,
      };
    });
  };

  const data = compileData();
  const layers = reportCreateLayers(data);

  let marginHeight = 50;
  if (data.length > 2) {
    marginHeight = data.length * 25;
  }
  return (
    <>
      <ResponsiveScatterPlot
        data={data}
        // symbolSize={18}
        // indexBy="date"
        yScale={{
          type: "linear",
          min: 0,
          max: 10,
        }}
        xScale={{
          type: "time",
          format: "%m-%d",
          precision: "day",
        }}
        axisBottom={{
          tickRotation: -40,
          format: "%b %d",
          tickValues: "every month",
        }}
        margin={{
          top: marginHeight, //need to change this depending on number in compare
          right: 10,
          bottom: 45,
          left: 36,
        }}
        colors={getAllColors()}
        enableGridX={true}
        tooltip={reportTooltip}
        legends={[
          {
            anchor: "top-left",
            direction: "column",
            translateY: -1 * marginHeight, //need to change this depending on number in compare
            translateX: 0,
            itemWidth: 10,
            itemHeight: 20,
            // dataFrom: "keys",
            symbolSize: 12,
            symbolShape: "circle",
          },
        ]}
        layers={layers}
      />
    </>
  );
}
