import React from "react";
import { useNavigate } from "react-router-dom";

import NurseryApi from "../api/Nursery.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import PageHeader from "../ui/PageHeader";
import { Nursery } from "../types";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { HttpError } from "../types/http-error";

export default function NurseryList() {
  const navigate = useNavigate();

  const { mutateAsync: nurseryCreate } = NurseryApi.useSave({});

  const handleCreate = async () => {
    try {
      const result = await nurseryCreate();
      navigate(`/nurseries/${result.id}`);
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Nursery");
      }
    }
  };

  return (
    <>
      <PageHeader title={"Nurseries"} half={6}>
        <IndexHeaderButton handleCreate={handleCreate} />
      </PageHeader>
      <SimpleListName
        useList={NurseryApi.useInfiniteList}
        useDelete={NurseryApi.useDelete}
        baseUrl="nurseries"
        headers={[
          { key: "name", label: "Name", getValue: (obj: Nursery) => obj?.name },
          {
            key: "commodity",
            label: "Commodity",
            getValue: (obj: Nursery) => obj?.commodity?.name,
          },
          {
            key: "wetDate",
            label: "Wet Date",
            getValue: (obj: Nursery) =>
              obj?.wetDate ? dayjs(obj.wetDate).format("LL") : "",
          },
          {
            key: "estTransferDate",
            label: "Est. Transfer Date",
            getValue: (obj: Nursery) =>
              obj.estTransferDate
                ? dayjs(obj.estTransferDate).format("LL")
                : "",
          },
          {
            key: "state",
            label: "State",
            getValue: (obj: Nursery) => (obj?.active ? "Active" : "Inactive"),
          },
        ]}
      />
    </>
  );
}
