import { liteClient } from "algoliasearch/lite";
import {InstantSearch, useInstantSearch} from "react-instantsearch";
import AlgoliaTrialList from "../components/AlgoliaTrialList";
import AlgoliaTrialSearchBox from "../components/AlgoliaTrialSearchBox";
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import React from "react";

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function PersonalizedTrials() {
  console.log('personalized trials env', process.env.NODE_ENV, process.env.REACT_APP_ALGOLIA_APPLICATION_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY)
  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={`Trial_${process.env.NODE_ENV}`}
        insights
      >
        <InsightsMiddleware />
        <AlgoliaTrialSearchBox personalized={true} />
        <AlgoliaTrialList />
      </InstantSearch>
    </>
  );
}

function InsightsMiddleware() {
  const { addMiddlewares } = useInstantSearch();

  React.useLayoutEffect(() => {
    const middleware = createInsightsMiddleware({
      // @ts-ignore
      insightsClient: window.aa,
    });

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  return null;
}