import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import EvaluationReportCard from "./EvaluationReportCard";
import MultiEvaluationReportCard from "./MultiEvaluationReportCard";
import CommentCard from "../../ui/CommentCard";
import { EvaluableType, FieldVariety, TrialVariety } from "../../types";
import ReportScoreCard from "../../ui/ReportScoreCard";
import ScrollableImageCard from "./ScrollableImageCard";

export default function EvaluationReportColumn({
  typeVariety,
  evaluableType,
  showGrowerComments,
  plotNumber,
  fieldVarieties,
}: {
  typeVariety: TrialVariety | FieldVariety;
  evaluableType: EvaluableType;
  showGrowerComments?: boolean;
  plotNumber?: string;
  fieldVarieties?: FieldVariety[];
}) {
  return (
    <Grid container spacing={2} sx={{ height: "100%" }}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{typeVariety.variety.name}</Typography>
        <Typography variant="body1">{plotNumber || "FV"}</Typography>
        <Typography variant="body1">
          {typeVariety.variety.company && typeVariety.variety.company.name}
        </Typography>
        <Typography variant="body1">
          Maturity Date:{" "}
          {dayjs(typeVariety.maturityDate).format("MMMM D, YYYY")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <EvaluationReportCard
          typeVariety={typeVariety}
          evaluableType={evaluableType}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiEvaluationReportCard
          typeVariety={typeVariety}
          evaluableType={evaluableType}
        />
      </Grid>
      <Grid item xs={12}>
        <CommentCard title={"Comments"} comments={typeVariety.comments} />
      </Grid>
      {showGrowerComments && (
        <Grid item xs={12}>
          <CommentCard
            title={"Grower Comments"}
            comments={typeVariety.growerComments}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ReportScoreCard
          trialVarietyScore={typeVariety.score}
          // @ts-ignore
          fieldVarietyScore={
            evaluableType === "field_variety"
              ? typeVariety.score
              : // @ts-ignore
                fieldVarieties.find(fv => fv.id === typeVariety.fieldVarietyId)
                  ?.score
          }
          // @ts-ignore
          fieldVarietyName={
            evaluableType === "field_variety"
              ? typeVariety.variety.name
              : // @ts-ignore
                fieldVarieties.find(fv => fv.id === typeVariety.fieldVarietyId)
                  ?.variety?.name
          }
        />
      </Grid>
      <Grid item xs={12}>
        <ScrollableImageCard
          parentType={evaluableType}
          parentId={typeVariety.id}
        />
      </Grid>
    </Grid>
  );
}
