import AddIcon from "@mui/icons-material/Add";

import React from "react";
import { liteClient } from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import ReportsSearch from "../components/algolia/ReportsSearch";
import ReportsVarietyList from "../components/algolia/ReportsVarietyList";
import { Box, Button, Typography } from "@mui/material";
import { remove } from "lodash";
import { useNavigate } from "react-router-dom";

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function Reports() {
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState<string[]>([]);

  const toggleChecked = (varietyId: string) => {
    setChecked(prevChecked => {
      if (prevChecked.indexOf(varietyId) === -1) {
        return [...prevChecked, varietyId];
      }
      const newChecked = remove(prevChecked, (id: string) => id !== varietyId);
      return [...newChecked];
    });
  };

  const handleNavigateCompare = () => {
    navigate({
      pathname: "/reports/compare",
      search: `?variety_ids=${checked.join(",")}`,
    });
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`Variety_${process.env.NODE_ENV}`}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant={"h4"}>Reports</Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={checked.length <= 1}
            variant={"contained"}
            onClick={handleNavigateCompare}
          >
            <AddIcon />
          </Button>
        </Box>
      </Box>
      <ReportsSearch />
      <ReportsVarietyList checked={checked} toggleChecked={toggleChecked} />
    </InstantSearch>
  );
}
