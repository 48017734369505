import { Autocomplete, TextField } from "@mui/material";
import { Commodity } from "../types";
import React from "react";
import CommodityApi from "../api/Commodity.api";

type Option = {
  label: string;
  value: Commodity;
};

export default function CommoditySelect({
  commodity,
  setCommodity,
  label,
}: {
  commodity?: Commodity;
  setCommodity: (arg: Commodity) => void;
  label: string;
}) {
  const [value, setValue] = React.useState<Option | null>(
    commodity ? { label: commodity.name, value: commodity } : null
  );
  const [inputValue, setInputValue] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const { data, isFetching } = CommodityApi.useList({ query: inputValue });
  const options: Option[] = data
    ? data.data.map(commodity => ({ label: commodity.name, value: commodity }))
    : [];
  return (
    <Autocomplete
      autoHighlight
      loading={isFetching}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event: any, newValue: Option | null) => {
        setValue(newValue);
        setCommodity(newValue?.value as Commodity);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={params => <TextField {...params} label={label} />}
    />
  );
}
