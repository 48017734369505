import React from "react";
import { useParams } from "react-router-dom";

import AreaApi from "../api/Area.api";
import AreaForm from "../components/AreaForm";

export default function AreaShow() {
  const { id } = useParams();
  const { data } = AreaApi.useDetail(id);
  return <>{data && <AreaForm area={data} />}</>;
}
