import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Commodity } from "../types";
import CommodityApi from "../api/Commodity.api";

type Option = {
  label: string;
  value: Commodity;
};

export default function AssociatedCommodities({
  selectedCommodities,
  setSelectedCommodities,
}: {
  selectedCommodities: Commodity[];
  setSelectedCommodities: (arg: Commodity[]) => void;
}) {
  const [inputValue, setInputValue] = React.useState<string>("");
  const [value, setValue] = React.useState<Option[]>(
    selectedCommodities.map(commodity => ({
      label: commodity.name,
      value: commodity,
    })) || []
  );
  const { data } = CommodityApi.useList({ query: inputValue });
  const options = data
    ? data.data.map(commodity => ({ label: commodity.name, value: commodity }))
    : [];

  return (
    <Autocomplete
      autoHighlight
      multiple
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      filterOptions={(options: Option[]) =>
        options.filter(
          option => !value.find(v => v.value.id === option.value.id)
        )
      }
      value={value}
      onChange={(event: any, newValues: Option[]) => {
        setValue(newValues);
        setSelectedCommodities(newValues.map(option => option.value));
      }}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          label="Associated Commodities"
          variant="outlined"
          placeholder={"Commodity"}
        />
      )}
    />
  );
}
