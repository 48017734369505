import React from "react";
import { useParams } from "react-router-dom";

import RanchApi from "../api/Ranch.api";
import RanchForm from "../components/RanchForm";

export default function RanchShow() {
  const { id } = useParams();
  const { data } = RanchApi.useDetail(id);
  return <>{data && <RanchForm ranch={data} />}</>;
}
