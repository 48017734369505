import { Card, CardContent, Typography } from "@mui/material";

export default function ReportScoreCard({
  trialVarietyScore,
  fieldVarietyScore,
  fieldVarietyName,
  color,
}: {
  trialVarietyScore: number | string;
  fieldVarietyScore: number | string;
  fieldVarietyName: string;
  color?: string;
}) {
  return (
    <Card style={{ backgroundColor: color }}>
      <CardContent>
        <Typography variant="subtitle1">Scores</Typography>
        <Typography variant="body1">
          Trial Variety: {trialVarietyScore}
        </Typography>
        <Typography variant="body1">
          {fieldVarietyName
            ? `${fieldVarietyName} : ${fieldVarietyScore}`
            : "No associated Field Variety"}
        </Typography>
      </CardContent>
    </Card>
  );
}
