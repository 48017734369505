import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import LabelApi from "../../api/Label.api";
import { useInView } from "react-intersection-observer";
import { Label } from "../../types";

export default function LabelSelectList({
  commodityId,
  attachedLabels,
  handleClose,
}: {
  attachedLabels?: Label[];
  commodityId: number;
  handleClose: () => void;
}) {
  const { ref } = useInView();
  const [query, setQuery] = React.useState<string>("");
  const { data, refetch, fetchNextPage, hasNextPage, isFetching } =
    LabelApi.useInfiniteList(query);

  const { mutateAsync: addLabel } = LabelApi.useAddLabelToCommodity({
    commodityId,
  });

  React.useEffect(() => {
    refetch();
  }, [refetch, query]);

  const selectLabel = (label: Label) => async () => {
    try {
      await addLabel({ labelId: label.id });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const attachedLabelIds = attachedLabels
    ? attachedLabels.map(label => label.id)
    : [];

  return (
    <Grid container spacing={2} sx={{ marginBottom: 1 }}>
      <Grid item xs={12}>
        <TextField
          id="input-with-icon-textfield"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          autoFocus={true}
          value={query}
          onChange={event => setQuery(event.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  Name
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.pages.map((group: any, i: number) => (
              <React.Fragment key={i}>
                {group.data
                  .filter(
                    (label: Label) => !attachedLabelIds.includes(label.id)
                  )
                  .map((label: Label) => (
                    <TableRow key={label.id}>
                      <TableCell>
                        <Typography variant="body2" gutterBottom>
                          {label.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          size="small"
                          aria-label="Delete"
                          onClick={selectLabel(label)}
                        >
                          <AddIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        {isFetching ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ marginTop: 30 }}
          >
            <CircularProgress size={80} thickness={7} color={"primary"} />
          </Grid>
        ) : (
          <Box>
            {!isFetching && hasNextPage && (
              <Button
                color="primary"
                fullWidth
                onClick={() => fetchNextPage()}
                ref={ref}
              >
                Show more results
              </Button>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
