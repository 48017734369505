import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";
import { UseInfiniteQueryResult } from "react-query";
import { Link } from "react-router-dom";

import { MetaPagination } from "../types";
import DeleteConfirmation from "./DeleteConfirmation";

type Header<Type> = {
  key: string;
  label: string;
  getValue: (arg: Type) => string;
};

export default function SimpleListName<Type>({
  useList,
  useDelete,
  baseUrl,
  headers,
}: {
  useList: (
    query?: string
  ) => UseInfiniteQueryResult<{ data: Type[]; meta: MetaPagination }>;
  useDelete: (arg: number) => any;
  baseUrl: string;
  headers: Header<Type>[];
}) {
  const { ref } = useInView();
  const [query, setQuery] = React.useState<string>("");
  const { data, refetch, isFetching, fetchNextPage, hasNextPage } =
    useList(query);

  React.useEffect(() => {
    refetch();
  }, [query, refetch]);

  const headerLabels = headers.map(header => header.label);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ marginBottom: 1 }}>
        <Grid item xs={12}>
          <TextField
            id="input-with-icon-textfield"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            autoFocus={true}
            value={query}
            onChange={event => setQuery(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              {[...headerLabels, ""].map((header, index) => (
                <TableCell key={index}>
                  <Typography variant="body1" gutterBottom>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.pages.map((group: any, i: number) => (
              <React.Fragment key={i}>
                {group.data.map((row: any) => (
                  <TableRow key={row.id}>
                    {headers.map(header => (
                      <TableCell key={header.key}>
                        <Link to={`/${baseUrl}/${row?.id}`}>
                          <Typography variant="body2" gutterBottom>
                            {header.getValue(row)}
                          </Typography>
                        </Link>
                      </TableCell>
                    ))}
                    <TableCell align="right">
                      <DeleteConfirmation useDelete={useDelete} id={row?.id} />
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        {isFetching ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ marginTop: 30 }}
          >
            <CircularProgress size={80} thickness={7} color={"primary"} />
          </Grid>
        ) : (
          <Box>
            {!isFetching && hasNextPage && (
              <Button
                color="primary"
                fullWidth
                onClick={() => fetchNextPage()}
                ref={ref}
              >
                Show more results
              </Button>
            )}
          </Box>
        )}
      </Grid>
    </Box>
  );
}
