import {
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import PersonApi from "../api/Person.api";
import { PersonInput } from "../types";
import AssociatedCommodities from "./AssociatedCommodities";
import AssociatedCompanies from "./AssociatedCompanies";
import AssociatedRegions from "./AssociatedRegions";
import toast from "react-hot-toast";
import { HttpError } from "../types/http-error";

export default function PersonForm({ person }: { person?: PersonInput }) {
  const navigate = useNavigate();
  const [firstName, setFirstName] = React.useState<string>(
    person?.firstName || ""
  );
  const [lastName, setLastName] = React.useState<string>(
    person?.lastName || ""
  );
  const [position, setPosition] = React.useState<string>(
    person?.position || ""
  );
  const [phoneNumber, setPhoneNumber] = React.useState<string>(
    person?.phoneNumber || ""
  );
  const [mobileNumber, setMobileNumber] = React.useState<string>(
    person?.mobileNumber || ""
  );
  const [website, setWebsite] = React.useState<string>(person?.website || "");
  const [email, setEmail] = React.useState<string>(person?.email || "");
  const [organic, setOrganic] = React.useState<boolean>(
    person?.organic || false
  );
  const [conventional, setConventional] = React.useState<boolean>(
    person?.conventional || false
  );

  const [companies, setCompanies] = React.useState(person?.companies || []);
  const [commodities, setCommodities] = React.useState(
    person?.commodities || []
  );
  const [regions, setRegions] = React.useState(person?.regions || []);

  const { mutateAsync, isLoading } = PersonApi.useSave({
    ...person,
    firstName,
    lastName,
    position,
    phoneNumber,
    mobileNumber,
    website,
    email,
    organic,
    conventional,
    companyIds: companies.map(company => company.id),
    commodityIds: commodities.map(commodity => commodity.id),
    regionIds: regions.map(region => region.id),
  });

  const onSave = async () => {
    try {
      const res = await mutateAsync();
      navigate(`/people/${res.id}`);
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Person");
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {person?.id ? "Edit Person" : "Create Person"}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          value={firstName}
          onChange={event => setFirstName(event.target.value)}
          label={"First Name"}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={lastName}
          onChange={event => setLastName(event.target.value)}
          label={"Last Name"}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          value={position}
          onChange={event => setPosition(event.target.value)}
          label={"Position"}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          value={phoneNumber}
          onChange={event => setPhoneNumber(event.target.value)}
          label={"Phone Number"}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          value={mobileNumber}
          onChange={event => setMobileNumber(event.target.value)}
          label={"Mobile Number"}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={website}
          onChange={event => setWebsite(event.target.value)}
          label={"Website"}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={email}
          onChange={event => setEmail(event.target.value)}
          label={"Email"}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Cultural Practice
        </Typography>
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Checkbox
                checked={organic}
                onChange={event => setOrganic(event.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Organic"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={conventional}
                onChange={event => setConventional(event.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Conventional"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <AssociatedCompanies
          selectedCompanies={companies}
          setSelectedCompanies={setCompanies}
        />
      </Grid>
      <Grid item xs={12}>
        <AssociatedRegions
          selectedRegions={regions}
          setSelectedRegions={setRegions}
        />
      </Grid>
      <Grid item xs={12}>
        <AssociatedCommodities
          selectedCommodities={commodities}
          setSelectedCommodities={setCommodities}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          component={Link}
          to="/people"
          variant="contained"
          color="secondary"
          fullWidth
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          disabled={isLoading}
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
