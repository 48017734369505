import React from "react";
import {
  Commodity,
  EvaluableType,
  FieldVariety,
  TrialVariety,
} from "../../types";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  InputAdornment,
  OutlinedInput,
  Grid,
} from "@mui/material";
import CommodityApi from "../../api/Commodity.api";
import Evaluations from "./Evaluations";
import { SelectChangeEvent } from "@mui/material/Select";
import MultiEvaluations from "./MultiEvaluations";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { isNil } from "lodash";
import EvaluationImages from "./EvaluationImages";
import toast from "react-hot-toast";

export default function SelectedTypeVariety({
  typeVariety,
  evaluableType,
  handleClose,
  useSave,
  children,
}: {
  typeVariety: FieldVariety | TrialVariety;
  evaluableType: EvaluableType;
  useSave: () => any;
  handleClose: () => void;
  children?: string | JSX.Element | JSX.Element[] | undefined;
}) {
  const [comments, setComments] = React.useState<string>(
    typeVariety.comments || ""
  );
  const [growerComments, setGrowerComments] = React.useState<string>(
    typeVariety.growerComments || ""
  );
  const [growerLike, setGrowerLike] = React.useState<boolean | undefined>(
    typeVariety.growerLike || undefined
  );
  const [maturityDate, setMaturityDate] = React.useState<Dayjs | null>(
    typeVariety.maturityDate
      ? dayjs(typeVariety.maturityDate, "YYYY-MM-DD")
      : null
  );

  const [selectedCommodity, setSelectedCommodity] = React.useState<
    Commodity | undefined
  >(typeVariety?.commodity || undefined);

  const { data: commodities } = CommodityApi.useByVariety(
    typeVariety.variety.id
  );

  React.useEffect(() => {
    setSelectedCommodity(typeVariety?.commodity);
    setComments(typeVariety.comments || "");
    setGrowerComments(typeVariety.growerComments || "");
    setGrowerLike(typeVariety?.growerLike);
    setMaturityDate(
      typeVariety.maturityDate
        ? dayjs(typeVariety.maturityDate, "YYYY-MM-DD")
        : null
    );
  }, [typeVariety]);

  const { mutateAsync: saveTypeVariety } = useSave();

  const handleCommodityChange = async (event: SelectChangeEvent<string>) => {
    const newSelectedCommodityId: number | undefined = event.target.value
      ? parseInt(event.target.value)
      : undefined;
    const newCommodity =
      commodities &&
      commodities.find(commodity => newSelectedCommodityId === commodity.id);
    setSelectedCommodity(newCommodity);
    try {
      await saveTypeVariety({
        ...typeVariety,
        commodityId: newCommodity?.id,
      });
      toast.success("Successfully saved commodity selection");
    } catch (e) {
      console.error(e);
      toast.error("Failed to save.");
    }
  };

  const handleSave = async () => {
    try {
      await saveTypeVariety({
        ...typeVariety,
        comments,
        growerComments,
        growerLike,
        maturityDate: maturityDate?.format("YYYY-MM-DD"),
      });
      toast.success("Successfully saved.");
    } catch (e) {
      console.error(e);
      toast.error("Failed to save.");
    }
  };

  console.log("typeVariety.score", typeVariety.score, typeVariety);

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        {children}
        <Box>
          {commodities && (
            <FormControl fullWidth>
              <InputLabel id="select-field-variety-commodity-label">
                Commodity
              </InputLabel>
              <Select
                fullWidth
                labelId="select-field-variety-commodity-label"
                id="select-field-variety-commodity"
                label={"Commodity"}
                value={selectedCommodity?.id?.toString() || ""}
                onChange={handleCommodityChange}
              >
                {commodities &&
                  commodities.map((commodity: Commodity) => (
                    <MenuItem
                      value={commodity.id?.toString()}
                      key={commodity.id}
                    >
                      {commodity.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box>
          {/* show all the evaluations for the field variety */}
          {selectedCommodity ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Grid container spacing={2}>
                <Evaluations
                  commodity={selectedCommodity}
                  evaluableId={typeVariety.id}
                  evaluableType={evaluableType}
                />
                <MultiEvaluations
                  commodity={selectedCommodity}
                  multiEvaluableId={typeVariety.id}
                  multiEvaluableType={evaluableType}
                />
                <Grid item xs={4} md={3}>
                  <DatePicker
                    label="Maturity Date"
                    value={maturityDate}
                    onChange={(newValue: Dayjs | null) =>
                      setMaturityDate(newValue)
                    }
                    sx={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              <FormControl fullWidth variant={"outlined"}>
                <InputLabel htmlFor="outlined-adornment">Comments</InputLabel>
                <OutlinedInput
                  fullWidth
                  label="Comments"
                  multiline
                  rows={4}
                  value={comments}
                  onChange={event => setComments(event.target.value)}
                  inputProps={{ maxLength: 350 }}
                  endAdornment={
                    <InputAdornment position="end">{`${
                      (comments || "").length
                    }/350`}</InputAdornment>
                  }
                />
              </FormControl>
              <FormControl fullWidth variant={"outlined"}>
                <InputLabel htmlFor="outlined-adornment">
                  Grower Comments
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  label="Grower Comments"
                  multiline
                  rows={4}
                  value={growerComments}
                  onChange={event => setGrowerComments(event.target.value)}
                  inputProps={{ maxLength: 350 }}
                  endAdornment={
                    <InputAdornment position="end">{`${
                      (growerComments || "").length
                    }/350`}</InputAdornment>
                  }
                />
              </FormControl>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <Box sx={{ flex: 1 }}>
                  <Typography variant={"body1"}>
                    Grower's Overall Felling
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    variant={
                      !isNil(growerLike) && growerLike
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setGrowerLike(true)}
                    startIcon={<ThumbUpIcon />}
                  >
                    Like
                  </Button>
                  <Button
                    variant={
                      !isNil(growerLike) && !growerLike
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setGrowerLike(false)}
                    startIcon={<ThumbDownIcon />}
                  >
                    Dislike
                  </Button>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <Typography variant={"body1"}>
                  Weighted Score: {typeVariety.score}
                </Typography>
              </Box>
              <EvaluationImages
                evaluableType={evaluableType}
                typeVariety={typeVariety}
              />
            </Box>
          ) : (
            <Typography>Must select a commodity inorder to proceed</Typography>
          )}
        </Box>
      </CardContent>
      <CardActions>
        <Button variant={"contained"} fullWidth onClick={handleSave}>
          Save
        </Button>
        <Button
          variant={"contained"}
          fullWidth
          color={"info"}
          onClick={handleClose}
        >
          Close
        </Button>
      </CardActions>
    </Card>
  );
}
