import React from "react";
import { useNavigate } from "react-router-dom";

import AreaApi from "../api/Area.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import PageHeader from "../ui/PageHeader";
import { Area } from "../types";

export default function AreaList() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader title={"Areas"} half={6}>
        <IndexHeaderButton
          handleCreate={() => {
            navigate("/areas/create");
          }}
        />
      </PageHeader>
      <SimpleListName
        useList={AreaApi.useInfiniteList}
        useDelete={AreaApi.useDelete}
        baseUrl="areas"
        headers={[
          { key: "name", label: "Name", getValue: (obj: Area) => obj.name },
        ]}
      />
    </>
  );
}
