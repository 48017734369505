import { Autocomplete, TextField } from "@mui/material";
import { Company } from "../types";
import React from "react";
import CompanyApi from "../api/Company.api";

type Option = {
  label: string;
  value: Company;
};

export default function CompanySelect({
  company,
  setCompany,
  label,
}: {
  company?: Company;
  setCompany: (arg: Company) => void;
  label: string;
}) {
  const [value, setValue] = React.useState<Option | null>(
    company ? { label: company.name, value: company } : null
  );
  const [inputValue, setInputValue] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const { data, isFetching } = CompanyApi.useList({ query: inputValue });
  const options: Option[] = data
    ? data.data.map(company => ({ label: company.name, value: company }))
    : [];
  return (
    <Autocomplete
      autoHighlight
      loading={isFetching}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event: any, newValue: Option | null) => {
        setValue(newValue);
        setCompany(newValue?.value as Company);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={params => <TextField {...params} label={label} />}
    />
  );
}
