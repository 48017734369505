import React from "react";
import { useNavigate } from "react-router-dom";

import UserApi from "../api/User.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import PageHeader from "../ui/PageHeader";
import { User } from "../types";

export default function ProfileList() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader title={"Users"} half={6}>
        <IndexHeaderButton
          handleCreate={() => {
            navigate("/users/create");
          }}
        />
      </PageHeader>
      <SimpleListName
        useList={UserApi.useInfiniteList}
        useDelete={UserApi.useDelete}
        baseUrl="users"
        headers={[
          {
            key: "lastName",
            label: "Last Name",
            getValue: (obj: User) => obj.lastName,
          },
          {
            key: "firstName",
            label: "First Name",
            getValue: (obj: User) => obj.firstName,
          },
          { key: "email", label: "Email", getValue: (obj: User) => obj.email },
          {
            key: "admin",
            label: "PD",
            getValue: (obj: User) => (obj.admin ? "Yes" : "No"),
          },
        ]}
      />
    </>
  );
}
