import {
  FieldVariety,
  PersonalView,
  PlotBed,
  PlotType,
  Trial,
  TrialVariety,
  Variety,
} from "./types";
import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import React from "react";
import {Typography} from "@mui/material";
import {line} from "d3-shape";

export const useDebounce = (callback: any) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = React.useMemo(() => {
    const func = () => {
      // @ts-ignore
      ref.current?.();
    };

    return debounce(func, 1000);
  }, []);

  return debouncedCallback;
};

export const filterPlotBedsByTrial = ({
  trial,
  trialVarieties,
  plotBeds,
}: {
  trial: Trial;
  trialVarieties: TrialVariety[];
  plotBeds: PlotBed[];
}) => {
  const filteredTrialVarieties: { [keyof: number]: TrialVariety } = {};
  trialVarieties.forEach(trialVariety => {
    if (trialVariety.trialId === trial.id) {
      filteredTrialVarieties[trialVariety.id] = trialVariety;
    }
  });
  const filteredPlotBeds: { [keyof: string]: PlotBed } = {};
  plotBeds.forEach(plotBed => {
    if (
      filteredTrialVarieties[plotBed.plotsId] &&
      plotBed.plotsType === "TrialVariety"
    )
      filteredPlotBeds[plotBed.key] = plotBed;
  });
  return filteredPlotBeds;
};

export const createPlotBedLocation = (row: number, col: number) =>
  `${row}-${col}`;

export const createPlotBedByLocation = (
  plotBeds?: PlotBed[]
): { [key: string]: PlotBed } => {
  const plotBedByLocation: { [key: string]: PlotBed } = {};
  plotBeds &&
    plotBeds.forEach(plotBed => {
      plotBedByLocation[createPlotBedLocation(plotBed.row, plotBed.col)] =
        plotBed;
    });
  return plotBedByLocation;
};

export const getPlotBedNumbers = ({
  plotBeds,
  plotType,
  rows,
  cols,
}: {
  plotBeds: { [key: string]: PlotBed };
  plotType: PlotType;
  rows: number;
  cols: number;
}) => {
  const content = createPlotBedObjects({ plotBeds, plotType, rows, cols });
  return content["grid"];
};

export const getVarietyPlotNumber = ({
  plotBeds,
  plotType,
  rows,
  cols,
}: {
  plotBeds: { [key: string]: PlotBed };
  plotType: PlotType;
  rows: number;
  cols: number;
}) => {
  const content = createPlotBedObjects({ plotBeds, plotType, rows, cols });
  return content["trialVarieties"];
};

export const getPlotNumbersByPlotId = ({
  plotBeds,
  plotType,
  rows,
  cols,
}: {
  plotBeds: { [key: string]: PlotBed };
  plotType: PlotType;
  rows: number;
  cols: number;
}) => {
  const content = createPlotBedObjects({ plotBeds, plotType, rows, cols });
  return content["plotBedNumbers"];
};

const createPlotBedObjects = ({
  plotBeds,
  plotType,
  rows,
  cols,
}: {
  plotBeds: { [key: string]: PlotBed };
  plotType: PlotType;
  rows: number;
  cols: number;
}) => {
  let plotNumberCounter = 1;
  const trialVarieties: { [key: number]: any } = {};
  const grid: { [key: string]: number } = {};
  const plotBedNumbers: { [key: number]: number } = {};
  for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
    let colLoop = normalColLoop(cols);
    if (plotType === "snake" && rowIndex % 2 !== 0) {
      colLoop = snakeColLoop(cols);
    }
    let colLoopNext = colLoop.next();
    while (!colLoopNext.done) {
      let colIndex = colLoopNext.value;

      const location = createPlotBedLocation(rowIndex, colIndex);
      let plotNumber = plotNumberCounter;
      let plotsId = null;
      if (plotBeds[location] && plotBeds[location].plotsId) {
        plotsId = plotBeds[location].plotsId;
      }
      if (plotsId) {
        if (trialVarieties[plotsId]) {
          plotNumber = trialVarieties[plotsId];
        } else {
          trialVarieties[plotsId] = plotNumber;
          plotNumberCounter += 1;
        }
        grid[location] = plotNumber;
        plotBedNumbers[plotsId] = plotNumber;
      }
      colLoopNext = colLoop.next();
    }
  }
  return { trialVarieties: trialVarieties, grid: grid, plotBedNumbers };
};

function* snakeColLoop(cols: number) {
  for (let colIndex = cols - 1; colIndex >= 0; colIndex--) {
    yield colIndex;
  }
}

function* normalColLoop(cols: number) {
  for (let colIndex = 0; colIndex < cols; colIndex++) {
    yield colIndex;
  }
}

export function camelToSnake(str: string) {
  return str
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + "_" + m[1];
    })
    .toLowerCase();
}

export function buildFilterHash({
  archived,
  mexico,
  excludeYears,
  wetDateStart,
  wetDateEnd,
  harvestDateStart,
  harvestDateEnd,
}: {
  archived: boolean;
  mexico: boolean;
  excludeYears: boolean;
  wetDateStart: Dayjs | null;
  wetDateEnd: Dayjs | null;
  harvestDateStart: Dayjs | null;
  harvestDateEnd: Dayjs | null;
}) {
  const filters: { [key: string]: any } = {};
  if (archived) {
    filters["archived"] = archived;
  }
  filters["mexican"] = mexico;
  if (excludeYears) {
    if (wetDateStart?.isValid()) {
      filters["startWetDateDays"] = wetDateStart.dayOfYear();
    }
    if (wetDateEnd?.isValid()) {
      filters["endWetDateDays"] = wetDateEnd.dayOfYear();
    }
    if (harvestDateStart?.isValid()) {
      filters["startHarvestDateDays"] = harvestDateStart.dayOfYear();
    }
    if (harvestDateEnd?.isValid()) {
      filters["endHarvestDateDays"] = harvestDateEnd.dayOfYear();
    }
  } else {
    if (wetDateStart?.isValid()) {
      filters["startWetDateDays"] = wetDateStart.unix();
    }
    if (wetDateEnd?.isValid()) {
      filters["endWetDateDays"] = wetDateEnd.unix();
    }
    if (harvestDateStart?.isValid()) {
      filters["startHarvestDateDays"] = harvestDateStart.unix();
    }
    if (harvestDateEnd?.isValid()) {
      filters["endHarvestDateDays"] = harvestDateEnd.unix();
    }
  }
  return filters;
}

export function buildFilters({
  archived,
  mexico,
  excludeYears,
  wetDateStart,
  wetDateEnd,
  harvestDateStart,
  harvestDateEnd,
}: {
  archived: boolean;
  mexico: boolean;
  excludeYears: boolean;
  wetDateStart: Dayjs | null;
  wetDateEnd: Dayjs | null;
  harvestDateStart: Dayjs | null;
  harvestDateEnd: Dayjs | null;
}) {
  const filterArr = [];
  const filtersHash = buildFilterHash({
    archived,
    mexico,
    excludeYears,
    wetDateStart,
    wetDateEnd,
    harvestDateStart,
    harvestDateEnd,
  });
  // @ts-ignore
  if (filtersHash["archived"] !== true) {
    filterArr.push("archived=0");
  }
  // @ts-ignore
  if (filtersHash["mexican"] === false) {
    filterArr.push("mexican=0");
  }
  // @ts-ignore
  Object.keys(filtersHash).forEach(key => {
    if (key.includes("start")) {
      // @ts-ignore
      const filterStr = `${key.replace("start", "")} >= ${filtersHash[key]}`;
      filterArr.push(camelToSnake(filterStr));
    } else if (key.includes("end")) {
      // @ts-ignore
      const filterStr = `${key.replace("end", "")} <= ${filtersHash[key]}`;
      filterArr.push(camelToSnake(filterStr));
    }
  });
  return filterArr.join(" AND ");
}

export function buildTagFilters({
  personalized,
  personalViews,
}: {
  personalized?: boolean;
  personalViews?: PersonalView[];
}) {
  const tagFilters: string[] = [];
  if (personalized && personalViews) {
    personalViews.forEach((pv: PersonalView) => {
      if (
        !(pv.companyId == null && pv.regionId == null && pv.commodityId == null)
      ) {
        const key = `${pv.companyId || 0}-${pv.regionId || 0}-${
          pv.commodityId || 0
        }`;
        const fullSearchValue = `${key}`;
        tagFilters.push(fullSearchValue);
      }
    });
  }
  return tagFilters;
}

export function allUniqueSuppliersFromVarieties(varieties: Variety[]) {
  const suppliers: { [key: number]: number } = {};
  varieties.forEach((variety: Variety) => {
    suppliers[variety.companyId] = variety.companyId;
  });
  return Object.keys(suppliers);
}

const colors = [
  "#ffd700",
  "#b9ceeb",
  "#148680",
  "#78145a",
  "#ff0522",
  "#6497b1",
  "#f37735",
  "#000046",
  "#ff6289",
  "#368155",
  "#2A363B",
  "#A8A7A7",
  "#A7226E",
  "#F26B38",
  "#45ADA8",
  "#E5FCC2",
  "#6C5B7B",
  "#F8B195",
];

export const getAllColors = () => colors;

export const getColor = (i: number) => colors[i % colors.length];

export const filterEmptyObjects = (
  combinedTrialAndFieldVarieties: (TrialVariety | FieldVariety)[]
) => {
  if (combinedTrialAndFieldVarieties === null) {
    return [];
  }
  let filterEmptyObjects = combinedTrialAndFieldVarieties.filter(obj => {
    if (obj.comments !== null && obj.comments.length !== 0) {
      return true;
    }
    if (obj.growerComments !== null && obj.growerComments.length !== 0) {
      return true;
    }
    // if (obj.evaluations.length !== 0) {
    //   return true;
    // }
    // if (obj.images.length !== 0) {
    //   return true;
    // }
    return false;
  });
  return filterEmptyObjects;
};

export const reportTooltip = (data: any) => {
  if(!data.node?.yValue || !data.node?.xValue){
    return <></>
  }
  const y = Math.round(data.node?.yValue * 100) / 100;
  const date = new Date(data.node?.xValue);
  return (
    <Typography variant="body1" gutterBottom>
      {dayjs(date).format("MMMM")}, {y}
    </Typography>
  );
};

export const reportCreateLayers = (data: any[]) => {
    const lines = data.map((dataGroup, index) => {
      const color = getColor(index);
      return LineOfAverages(color, dataGroup.id);
    });
    let layers: any[] = [];
    layers = layers.concat(["grid", "axes", "nodes"]);
    layers = layers.concat(lines);
    layers = layers.concat(["markers", "mesh", "legends"]);
    return layers;
  };


const LineOfAverages = (color: string, idName: any) => ({ nodes, xScale, yScale }: any) => {
  const filteredPoints = nodes.filter((point: any) =>
    !!point.id.includes(idName)
  );
  const monthAverages = getMonthAverages(filteredPoints);
  if (Object.keys(monthAverages).length <= 1) {
    return;
  }
  const lineGenerator = line()
    .x(d => {
      // @ts-ignore
      const date = d.data.x;
      date.setDate(15);
      return xScale(date);
    })
    .y(d => {
      // @ts-ignore
      const date = d.data.x;
      return yScale(monthAverages[date.getMonth()]);
    });

  const circlePoints = filteredPoints.map((point:any) => {
    const date = point.data.x;
    date.setDate(15);
    const y = monthAverages[date.getMonth()];
    return { x: xScale(date), y: yScale(y) };
  });
  const sortedFilteredPoints = filteredPoints.sort(
    (pointA: any, pointB: any) => pointA.x - pointB.x
  );
  return (
    <React.Fragment>
      <path
        // @ts-ignore
        d={lineGenerator(sortedFilteredPoints)}
        fill="none"
        style={{ pointerEvents: "none" }}
        stroke={color}
      />
      {circlePoints.map((point: any, index: number) => (
        <circle
          key={index}
          r={5}
          strokeWidth={2}
          cx={point.x}
          cy={point.y}
          fill="white"
          stroke={color}
          style={{ pointerEvents: "none" }}
        />
      ))}
    </React.Fragment>
  );
};

const getMonthAverages = (points: any[]) => {
  const months: { [key: string]: any } = {};
  points.forEach((point: any) => {
    const date = point.data.x;
    const month = date.getMonth().toString();
    if (months[month]) {
      months[month].push(point.data.y);
    } else {
      months[month] = [point.data.y];
    }
  });
  const monthAverages: { [key: string]: number } = {};
  Object.keys(months).forEach(monthKey => {
    const total = months[monthKey].reduce((total: number, num: number) => total + num);
    monthAverages[monthKey] = total / months[monthKey].length;
  });
  return monthAverages;
};
