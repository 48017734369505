import { handleBasicGet } from "./handler";
import { useQuery } from "react-query";
import { ReportStatus } from "../types";

const methods = {
  useStatus: ({ id }: { id?: number }) => {
    return useQuery({
      queryKey: ["report", id?.toString()],
      queryFn: () => handleBasicGet<ReportStatus>({ url: `reports/${id}` }),
      enabled: !!id,
      cacheTime: 0,
    });
  },
};
export default methods;
