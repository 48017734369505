import { useReportCompareContext } from "../../contexts/ReportCompare.context";
import { FieldVariety, TrialVariety } from "../../types";
import dayjs from "dayjs";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import React from "react";
import { getAllColors, reportTooltip, reportCreateLayers } from "../../utils";

export default function ScatterPlotSingle() {
  const { varieties, filteredCombinedVarieties } = useReportCompareContext();

  const compileData = () => {
    const fieldVarietyScoresHash: { [key: number]: FieldVariety } = {};
    const trialVarietyScoresHash: { [key: number]: TrialVariety } = {};
    filteredCombinedVarieties &&
      filteredCombinedVarieties.forEach(column => {
        if (column.evaluableType === "field_variety") {
          fieldVarietyScoresHash[column.typeVariety.id] = column.typeVariety;
        }
        if (column.evaluableType === "trial_variety") {
          if (
            varieties &&
            Object.keys(varieties).includes(
              column.typeVariety.varietyId?.toString()
            )
          ) {
            // @ts-ignore
            trialVarietyScoresHash[column.typeVariety.id] = column.typeVariety;
          }
          // @ts-ignore
          const fieldVariety = column.typeVariety?.fieldVariety;
          if (fieldVariety) {
            fieldVarietyScoresHash[fieldVariety.id] = fieldVariety;
          }
        }
      });
    return [
      {
        id: "Field Variety Scores",
        data: formatTypeVarietiesForGraph(
          Object.values(fieldVarietyScoresHash)
        ),
      },
      {
        id: "Weighted Scores",
        data: formatTypeVarietiesForGraph(
          Object.values(trialVarietyScoresHash)
        ),
      },
    ];
  };

  const formatTypeVarietiesForGraph = (
    typeVarieties: (FieldVariety | TrialVariety)[]
  ) =>
    typeVarieties
      .filter(
        typeVariety =>
          !!(typeVariety.score !== null && typeVariety.maturityDate)
      )
      .map(typeVariety => {
        const maturityDate = dayjs(typeVariety.maturityDate);
        return {
          x: maturityDate.format("M-D"),
          y: typeVariety.score,
        };
      });

  const data = compileData();
  const layers = reportCreateLayers(data);

  let marginHeight = 50;
  if (data.length > 2) {
    marginHeight = data.length * 25;
  }
  return (
    <ResponsiveScatterPlot
      data={data}
      // symbolSize={18}
      // indexBy="date"
      yScale={{
        type: "linear",
        min: 0,
        max: 10,
      }}
      xScale={{
        type: "time",
        format: "%m-%d",
        precision: "day",
      }}
      axisBottom={{
        tickRotation: -40,
        format: "%b %d",
        tickValues: "every month",
      }}
      margin={{
        top: marginHeight, //need to change this depending on number in compare
        right: 10,
        bottom: 45,
        left: 36,
      }}
      colors={getAllColors()}
      enableGridX={true}
      tooltip={reportTooltip}
      legends={[
        {
          anchor: "top-left",
          direction: "column",
          translateY: -1 * marginHeight, //need to change this depending on number in compare
          translateX: 0,
          itemWidth: 10,
          itemHeight: 20,
          // dataFrom: "keys",
          symbolSize: 12,
          symbolShape: "circle",
        },
      ]}
      layers={layers}
    />
  );
}
