import * as Sentry from "@sentry/browser";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { Session } from "../types";
import { jwtDecode } from "jwt-decode";
// import LogRocket from "logrocket";

type Props = {
  children: JSX.Element;
};

interface ISessionContext {
  session?: Session;
  loadedSessionStorage: boolean;
  setSession: (p: Session | undefined) => void;
}

const SessionContext = React.createContext<Partial<ISessionContext>>({});

export function SessionProvider({ children }: Props) {
  const [loadedSessionStorage, setLoadedSessionStorage] = React.useState(false);
  const [session, setSession] = React.useState<Session | undefined>();
  console.log("session", session);

  React.useEffect(() => {
    const data = window.localStorage.getItem(
      process.env.REACT_APP_PROFILE_LOCAL_STORAGE!
    );
    if (!(isNil(data) || isEmpty(data))) {
      const session = JSON.parse(data || "");
      const currentTime = Date.now() / 1000;
      const jwtToken = session["idToken"];
      const decodedToken = jwtDecode(jwtToken);
      if (decodedToken?.exp && decodedToken?.exp > currentTime) {
        setSession(session);
        Sentry.setUser({ email: session.email });
        // LogRocket.identify(session.userId, {
        //   email: session.email,

        //   // Add your own custom user variables here, ie:
        //   // subscriptionType: 'pro'
        // });
      } else {
        setSession(undefined);
      }
    }
    setLoadedSessionStorage(true);
  }, []);

  React.useEffect(() => {
    if (session) {
      window.localStorage.setItem(
        process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
        JSON.stringify(session)
      );
      // TODO
      // check timestamp if the user is still logged in, redirect and clear session if invalid
      if (process.env.NODE_ENV === "production" && session) {
        Sentry.setUser({
          id: session?.userId?.toString(),
          email: session?.email,
        });
      }
    }
    if (session === undefined) {
      // window.localStorage.removeItem(
      //   process.env.REACT_APP_PROFILE_LOCAL_STORAGE!
      // );
    }
  }, [session]);

  return (
    <SessionContext.Provider
      value={{
        session,
        loadedSessionStorage,
        setSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  return React.useContext(SessionContext);
}
