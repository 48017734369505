import React from "react";
import { useParams } from "react-router-dom";

import VarietyApi from "../api/Variety.api";
import VarietyForm from "../components/VarietyForm";

export default function VarietyShow() {
  const { id } = useParams();
  const { data, refetch } = VarietyApi.useDetail(id);
  console.log("Varietiy Detail", data);
  return <>{data && <VarietyForm variety={data} refetch={refetch} />}</>;
}
