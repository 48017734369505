import {
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  Checkbox,
  FormGroup,
  Button,
} from "@mui/material";
import { Commodity, NurseryInput, NurseryVariety, PlotType } from "../types";

import React from "react";
import CommoditySelect from "./CommoditySelect";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { Link } from "react-router-dom";
import { isNumber } from "lodash";
import PlotGrid from "./PlotGrid";
import NurseryApi from "../api/Nursery.api";
import NurseryVarietyApi from "../api/NurseryVariety.api";

export default function NurseryForm({
  nursery,
  nurseryVarieties,
}: {
  nursery: NurseryInput;
  nurseryVarieties: NurseryVariety[];
}) {
  const { mutateAsync: deleteNurseryVariety } = NurseryVarietyApi.useDelete();
  const { mutateAsync: createNurseryVariety } = NurseryVarietyApi.useSave();

  const [name, setName] = React.useState<string>(nursery?.name || "");
  const [commodity, setCommodity] = React.useState<Commodity | undefined>(
    nursery?.commodity
  );
  const [wetDate, setWetDate] = React.useState<Dayjs | null>(
    nursery?.wetDate ? dayjs(nursery.wetDate) : null
  );
  const [estTransferDate, setEstTransferDate] = React.useState<Dayjs | null>(
    nursery?.estTransferDate ? dayjs(nursery.estTransferDate) : null
  );
  const [active, setActive] = React.useState<boolean>(nursery?.active || false);
  const [comments, setComments] = React.useState<string>(
    nursery?.comments || ""
  );
  const [plotRows, setPlotRows] = React.useState<number | string>(
    nursery?.plotRows || ""
  );
  const [plotCols, setPlotCols] = React.useState<number | string>(
    nursery?.plotCols || ""
  );
  const [plotType, setPlotType] = React.useState<PlotType>(
    nursery?.plotType || "normal"
  );

  const { mutateAsync, isLoading } = NurseryApi.useSave({
    id: nursery?.id,
    name,
    commodityId: commodity?.id,
    wetDate: wetDate ? wetDate.format("YYYY-MM-DD") : undefined,
    estTransferDate: estTransferDate
      ? estTransferDate.format("YYYY-MM-DD")
      : undefined,
    active,
    comments,
    plotRows: isNumber(plotRows) ? plotRows : 0,
    plotCols: isNumber(plotCols) ? plotCols : 0,
    plotType,
  });

  const handleSave = async () => {
    try {
      await mutateAsync();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h2"} gutterBottom>
          {nursery?.id ? "Edit Nursery" : "Create Nursery"}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label={"name"}
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <CommoditySelect
          label="Commodity"
          commodity={commodity}
          setCommodity={setCommodity}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label={"Wet Date"}
          value={wetDate}
          onChange={(newValue: Dayjs | null) => setWetDate(newValue)}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label={"Est. Transfer Date"}
          value={estTransferDate}
          onChange={(newValue: Dayjs | null) => setEstTransferDate(newValue)}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={active}
              onChange={event => setActive(event.target.checked)}
            />
          }
          label="Active"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Notes"
          multiline
          rows={4}
          value={comments}
          onChange={event => setComments(event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={plotRows}
          onChange={event => {
            const value = event.target.value;
            const parsedValue = parseInt(value);
            if (!isNaN(parsedValue) && parsedValue >= 0) {
              setPlotRows(parseInt(value));
            } else {
              setPlotRows("");
            }
          }}
          label="Plot Rows"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={plotCols}
          onChange={event => {
            const value = event.target.value;
            const parsedValue = parseInt(value);
            if (!isNaN(parsedValue) && parsedValue >= 0) {
              setPlotCols(parseInt(value));
            } else {
              setPlotCols("");
            }
          }}
          label="Plot Columns"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={plotType === "normal"}
                onChange={event =>
                  setPlotType(event.target.checked ? "normal" : "snake")
                }
              />
            }
            label="Normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={plotType === "snake"}
                onChange={event =>
                  setPlotType(event.target.checked ? "snake" : "normal")
                }
              />
            }
            label="Snake"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <PlotGrid
          createTypeVariety={createNurseryVariety}
          deleteTypeVariety={deleteNurseryVariety}
          parentId={nursery?.id}
          typeVarieties={nurseryVarieties}
          grandParentTypeVariety={"nursery"}
          plotType={plotType}
          plotRows={isNumber(plotRows) ? plotRows : 0}
          plotCols={isNumber(plotCols) ? plotCols : 0}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          component={Link}
          to="/nurseries"
          variant="contained"
          color="info"
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
}
