import React from "react";
import { useParams } from "react-router-dom";

import RegionApi from "../api/Region.api";
import RegionForm from "../components/RegionForm";

export default function RegionShow() {
  const { id } = useParams();
  const { data } = RegionApi.useDetail(id);
  return <>{data && <RegionForm region={data} />}</>;
}
