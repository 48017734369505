import React from "react";
import { useNavigate } from "react-router-dom";

import CommodityApi from "../api/Commodity.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import PageHeader from "../ui/PageHeader";
import { Commodity } from "../types";
import { HttpError } from "../types/http-error";
import toast from "react-hot-toast";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";

export default function CommodityList() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");

  const { mutateAsync: createCommodity, isLoading } = CommodityApi.useSave({
    name,
  });

  const handleCreate = async () => {
    try {
      const result = await createCommodity();
      navigate(`/commodities/${result.id}`);
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Commodity");
      }
    }
  };

  return (
    <>
      <PageHeader title={"Commodities"} half={6}>
        <>
          <IndexHeaderButton handleCreate={() => setOpenModal(true)} />
          <Modal open={openModal} onClose={() => setOpenModal(false)}>
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Typography variant={"h6"}>Create Commodity</Typography>
              <TextField
                variant={"outlined"}
                label={"Name"}
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value);
                }}
                fullWidth
              />
              <Button
                disabled={isLoading}
                onClick={handleCreate}
                fullWidth
                variant={"contained"}
                color={"primary"}
              >
                Create
              </Button>
            </Box>
          </Modal>
        </>
      </PageHeader>
      <SimpleListName
        useList={CommodityApi.useInfiniteList}
        useDelete={CommodityApi.useDelete}
        baseUrl="commodities"
        headers={[
          {
            key: "name",
            label: "Name",
            getValue: (obj: Commodity) => obj.name,
          },
        ]}
      />
    </>
  );
}
