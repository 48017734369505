import React from "react";
import { useParams } from "react-router-dom";

import CompanyApi from "../api/Company.api";
import CompanyForm from "../components/CompanyForm";

export default function CompanyShow() {
  const { id } = useParams();
  const { data } = CompanyApi.useDetail(id);
  console.log("Company", data);
  return <>{data && <CompanyForm company={data} />}</>;
}
