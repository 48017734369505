import React from "react";
import { useNavigate } from "react-router-dom";

import RanchApi from "../api/Ranch.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import PageHeader from "../ui/PageHeader";
import { Ranch } from "../types";

export default function RanchList() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader title={"Ranches"} half={6}>
        <IndexHeaderButton
          handleCreate={() => {
            navigate("/ranches/create");
          }}
        />
      </PageHeader>
      <SimpleListName
        useList={RanchApi.useInfiniteList}
        useDelete={RanchApi.useDelete}
        baseUrl="ranches"
        headers={[
          { key: "name", label: "Name", getValue: (obj: Ranch) => obj.name },
        ]}
      />
    </>
  );
}
