import { handleDelete, handleSave, handleSimpleList } from "./handler";
import { useMutation, useQuery } from "react-query";
import { PersonalView, PersonalViewInput } from "../types";
import queryClient from "./queryClient";

const methods = {
  useList: (userId?: number | string) => {
    return useQuery({
      queryKey: ["personal_view", userId],
      queryFn: () =>
        handleSimpleList<PersonalView>({
          params: [{ key: "user_id", value: userId }],
          url: "personal_views",
        }),
      enabled: !!userId,
    });
  },
  useSave: (input: PersonalViewInput) => {
    return useMutation<PersonalView>({
      mutationFn: () => handleSave({ baseUrl: "personal_views", input }),
      retry: 1,
      onSuccess: async data => {
        await queryClient.refetchQueries(["personal_view", data.userId]);
      },
    });
  },
  useDelete: (id: number | string) => {
    return useMutation<{ id: number; userId: number }>({
      mutationFn: () => handleDelete({ baseUrl: "personal_views", id }),
      onSuccess: async ({ id, userId }: { id: number; userId: number }) => {
        queryClient.setQueryData(["personal_view", userId], (oldData: any) => {
          return oldData.filter(
            (personalView: PersonalView) =>
              personalView.id?.toString() !== id?.toString()
          );
        });
      },
    });
  },
};
export default methods;
