import React from "react";
import { useParams } from "react-router-dom";

import UserApi from "../api/User.api";
import UserForm from "../components/UserForm";

export default function ProfileShow() {
  const { id } = useParams();
  const { data } = UserApi.useDetail(id);
  return <>{data && <UserForm user={data} />}</>;
}
