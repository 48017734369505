import React from "react";

import CompanyForm from "../components/CompanyForm";

export default function CompanyCreate() {
  return (
    <>
      <CompanyForm />
    </>
  );
}
