import { useMutation } from "react-query";
import { handleSimpleList } from "./handler";
import {
  CulturalPractice,
  HarvestDateType,
  Param,
  StatisticRecord,
} from "../types";
import { Dayjs } from "dayjs";

const methods = {
  useCommodity: () => {
    return useMutation({
      mutationFn: ({
        areaId,
        commodityId,
        culturalPractice,
        dateType,
        endDate,
        regionId,
        startDate,
        varietyId,
      }: {
        areaId?: number;
        commodityId: number;
        culturalPractice: CulturalPractice;
        dateType: HarvestDateType;
        endDate: Dayjs;
        regionId: number;
        startDate: Dayjs;
        varietyId?: number;
      }) => {
        const params: Param[] = [];
        params.push({
          key: "commodity_id",
          value: commodityId,
        });
        params.push({
          key: "date_type",
          value: dateType,
        });
        params.push({
          key: "cultural_practice",
          value: culturalPractice,
        });
        params.push({
          key: "end_date",
          value: endDate.format("YYYY-MM-DD"),
        });
        params.push({
          key: "start_date",
          value: startDate.format("YYYY-MM-DD"),
        });
        if (areaId) {
          params.push({
            key: "area_id",
            value: areaId,
          });
        }
        if (regionId) {
          params.push({
            key: "region_id",
            value: regionId,
          });
        }
        if (varietyId) {
          params.push({
            key: "variety_id",
            value: varietyId,
          });
        }
        return handleSimpleList<StatisticRecord>({
          url: "statistics/commodity",
          params,
        });
      },
    });
  },
};

export default methods;
