import ReportApi from "../api/Report.api";
import React from "react";
import { Box, LinearProgress, Link, Typography } from "@mui/material";

const intervalTime = 5000;
export default function ReportStatusComponent({
  reportId,
}: {
  reportId: number;
}) {
  const { data, refetch } = ReportApi.useStatus({ id: reportId });

  React.useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, intervalTime);
    return () => clearInterval(interval);
  }, [refetch]);

  if (data && data.status === "completed" && data.link) {
    // data.link, data.error, data.percentageLeft
    return (
      <Box sx={{ flex: 1, py: "2rem" }}>
        <Link
          underline="always"
          href={data.link}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          Link to download file
        </Link>
      </Box>
    );
  } else if (data && data.status === "error") {
    console.error(data.error);
    return (
      <Box sx={{ flex: 1, py: "2rem" }}>
        <Typography variant="h4" gutterBottom>
          Error generating report, please contact your web administrator.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flex: 1, py: "2rem" }}>
      {data && data?.percentageLeft !== 0 ? (
        <LinearProgress
          color={"primary"}
          variant="determinate"
          value={data.percentageLeft}
        />
      ) : (
        <LinearProgress color={"primary"} />
      )}
    </Box>
  );
}
