import React from "react";
import NurseryApi from "../api/Nursery.api";
import { useParams } from "react-router-dom";
import NurseryForm from "../components/NurseryForm";
import NurseryVarietyApi from "../api/NurseryVariety.api";

export default function NurseryShow() {
  const { id } = useParams();
  const { data } = NurseryApi.useDetail(id);
  const { data: nurseryVarieties } = NurseryVarietyApi.useList(id);
  return (
    <>
      {data && nurseryVarieties && (
        <NurseryForm nursery={data} nurseryVarieties={nurseryVarieties} />
      )}
    </>
  );
}
