import { useReportCompareContext } from "../../contexts/ReportCompare.context";
import TrialEvaluationColumn from "../reports/TrialEvaluationColumn";
import { Box } from "@mui/material";
import { ReportType } from "../../types";
import dayjs from "dayjs";

export default function EvaluationColumns({
  reportType,
}: {
  reportType: ReportType;
}) {
  const { excludeYears, searchByWetDate, filteredCombinedVarieties } =
    useReportCompareContext();

  const sortedColumns = (filteredCombinedVarieties || []).sort((a, b) => {
    const convertDate = excludeYears
      ? (d: string) => dayjs(d).dayOfYear()
      : (d: string) => dayjs(d);
    const orderByWetDate = searchByWetDate;
    let dateA: any = null;
    let dateB: any = null;
    if (orderByWetDate) {
      const wetDateA = a.typeVariety?.trial?.wetDate;
      dateA = convertDate(wetDateA);
      const wetDateB = b.typeVariety?.trial?.wetDate;
      dateB = convertDate(wetDateB);
    } else {
      const maturityDateA = a.typeVariety?.maturityDate;
      const maturityDateB = b.typeVariety?.maturityDate;
      dateA = convertDate(maturityDateA);
      dateB = convertDate(maturityDateB);
    }
    if (dateA > dateB) {
      return 1;
    }
    if (dateA < dateB) {
      return -1;
    }
    return 0;
  });

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
      }}
    >
      {sortedColumns.map(column => (
        <Box
          key={column.typeVariety.id}
          sx={{
            margin: "8px",
            // border: "1px solid red"
          }}
        >
          <TrialEvaluationColumn
            reportType={reportType}
            typeVariety={column.typeVariety}
            evaluableType={column.evaluableType}
          />
        </Box>
      ))}
    </Box>
  );
}
