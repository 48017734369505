import React from "react";
import { useNavigate } from "react-router-dom";

import CompanyApi from "../api/Company.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import PageHeader from "../ui/PageHeader";
import { Company } from "../types";
import { capitalize } from "lodash";

export default function CompanyList() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader title={"Companies"} half={6}>
        <IndexHeaderButton
          handleCreate={() => {
            navigate("/companies/create");
          }}
        />
      </PageHeader>
      <SimpleListName
        useList={CompanyApi.useInfiniteList}
        useDelete={CompanyApi.useDelete}
        baseUrl="companies"
        headers={[
          { key: "name", label: "Name", getValue: (obj: Company) => obj.name },
          {
            key: "categories",
            label: "Categories",
            getValue: (obj: Company) =>
              obj?.companyCategories
                ? obj?.companyCategories
                    .map(category => capitalize(category?.name || ""))
                    .join(", ")
                : "",
          },
          {
            key: "regions",
            label: "Regions",
            getValue: (obj: Company) =>
              obj?.regions
                ? obj?.regions.map(region => region?.name).join(", ")
                : "",
          },
        ]}
      />
    </>
  );
}
