import { Autocomplete, TextField } from "@mui/material";
import RegionApi from "../api/Region.api";
import React from "react";
import { Region } from "../types";

type Option = {
  label: string;
  value: Region;
};

export default function AssociatedRegions({
  selectedRegions,
  setSelectedRegions,
}: {
  selectedRegions: Region[];
  setSelectedRegions: (arg: Region[]) => void;
}) {
  const [inputValue, setInputValue] = React.useState<string>("");
  const [value, setValue] = React.useState<Option[]>(
    selectedRegions.map(region => ({ label: region.name, value: region })) || []
  );
  const { data } = RegionApi.useList({ query: inputValue });
  const options = data
    ? data.data.map(region => ({ label: region.name, value: region }))
    : [];

  console.log("associated regions", value);

  return (
    <Autocomplete
      autoHighlight
      multiple
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      isOptionEqualToValue={(option: Option, value: Option) => {
        return option.value.id === value.value.id;
      }}
      filterOptions={(options: Option[]) =>
        options.filter(
          option => !value.find(v => v.value.id === option.value.id)
        )
      }
      value={value}
      onChange={(event: any, newValues: Option[]) => {
        setValue(newValues);
        setSelectedRegions(newValues.map(option => option.value));
      }}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          label="Associated Regions"
          variant="outlined"
          placeholder={"Region"}
        />
      )}
    />
  );
}
