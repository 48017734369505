import CompanyCategoryApi from "../api/CompanyCategory.api";
import { CompanyCategory } from "../types";
import React from "react";
import { Autocomplete, TextField } from "@mui/material";

type Option = {
  label: string;
  value: CompanyCategory;
};

export default function AssociatedCompanyCategories({
  selectedCompanyCategories,
  setSelectedCompanyCategories,
}: {
  selectedCompanyCategories: CompanyCategory[];
  setSelectedCompanyCategories: (arg: CompanyCategory[]) => void;
}) {
  const [inputValue, setInputValue] = React.useState<string>("");
  const [value, setValue] = React.useState<Option[]>(
    selectedCompanyCategories.map(category => ({
      label: category.name,
      value: category,
    })) || []
  );
  const { data } = CompanyCategoryApi.useList();
  const options = data
    ? data.map((category: CompanyCategory) => ({
        label: category.name,
        value: category,
      }))
    : [];

  return (
    <Autocomplete
      autoHighlight
      multiple
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option: Option, value: Option) => {
        return option.value.id === value.value.id;
      }}
      options={options}
      filterOptions={(options: Option[]) =>
        options.filter(
          option => !value.find(v => v.value.id === option.value.id)
        )
      }
      value={value}
      onChange={(event: any, newValues: Option[]) => {
        setValue(newValues);
        setSelectedCompanyCategories(newValues.map(option => option.value));
      }}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          label="Associated Categories"
          variant="outlined"
          placeholder={"Categories"}
          // slotProps={{
          //   input: {
          //     ...params.InputProps,
          //     endAdornment: (
          //       <React.Fragment>
          //         {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
          //         {params.InputProps.endAdornment}
          //       </React.Fragment>
          //     ),
          //   },
          // }}
        />
      )}
    />
  );
}
