import { Card, CardContent, Typography } from "@mui/material";

export default function CommentCard({
  title,
  comments,
  color,
}: {
  title: string;
  comments: string;
  color?: string;
}) {
  return (
    <Card style={{ backgroundColor: color ? color : "#fff" }}>
      <CardContent>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body1">{comments}</Typography>
      </CardContent>
    </Card>
  );
}
