import React from "react";
import { useParams } from "react-router-dom";

import PersonApi from "../api/Person.api";
import PersonForm from "../components/PersonForm";

export default function PersonShow() {
  const { id } = useParams();
  const { data } = PersonApi.useDetail(id);
  return <>{data && <PersonForm person={data} />}</>;
}
