import React from "react";

import RanchForm from "../components/RanchForm";

export default function RanchCreate() {
  return (
    <>
      <RanchForm />
    </>
  );
}
