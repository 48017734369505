import { TextField } from "@mui/material";
import { Company, CompanyCategoryName } from "../types";
import CompanyApi from "../api/Company.api";
import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

type Option = {
  label: string;
  value: Company;
};

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: Option) => option.label,
});

export default function CompanyMultiSelect({
  companies,
  setCompanies,
  label,
  companyCategoryName,
}: {
  companies?: Company[];
  setCompanies: (arg: Company[]) => void;
  label: string;
  companyCategoryName: CompanyCategoryName;
}) {
  const [value, setValue] = React.useState<Option[]>(
    companies
      ? companies.map(company => ({ value: company, label: company.name }))
      : []
  );
  const [open, setOpen] = React.useState<boolean>(false);

  const { data, isFetching } = CompanyApi.useListByCompanyCategory({
    query: "",
    companyCategoryName,
  });

  const options: Option[] = data
    ? data.map(company => ({ label: company.name, value: company }))
    : [];
  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      autoHighlight
      loading={isFetching}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event: any, newValue: Option[]) => {
        setValue(newValue);
        setCompanies(newValue.map(option => option.value) as Company[]);
      }}
      isOptionEqualToValue={(option: Option, value: Option) =>
        option.value.id === value.value.id
      }
      filterOptions={filterOptions}
      // inputValue={inputValue}
      // onInputChange={(event, newInputValue) => {
      //   setInputValue(newInputValue);
      // }}
      options={options}
      renderInput={params => (
        <TextField variant="standard" {...params} label={label} />
      )}
    />
  );
}
