import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import LockIcon from "@mui/icons-material/LockOutlined";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CssBaseline,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Link } from "react-router-dom";

import SessionApi from "../api/Session.api";
import toast from "react-hot-toast";
import { HttpError } from "../types/http-error";

export default function ForgotPassword() {
  const theme = useTheme();

  const [email, setEmail] = React.useState<string>("");
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);

  const { isLoading, isError, mutateAsync } = SessionApi.useForgotPassword({
    email,
  });

  const handleSend = async () => {
    try {
      setShowSuccess(false);
      await mutateAsync();
      setShowSuccess(true);
    } catch (error) {
      setShowSuccess(false);
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to send reset password link to email.");
      }
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          width: "auto",
          display: "block", // Fix IE11 issue.
          marginLeft: theme.spacing(3),
          marginRight: theme.spacing(3),
          paddingTop: theme.spacing(10),
          [theme.breakpoints.up(400)]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: theme.spacing(2, 3, 3),
          }}
          elevation={2}
        >
          <Avatar
            sx={{
              margin: theme.spacing(1),
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="subtitle1" color="initial">
            Reset Password
          </Typography>
          <Box
            sx={{
              width: "100%", // Fix IE11 issue.
              marginTop: theme.spacing(1),
            }}
            // onSubmit={this.props.handleSubmit(this.validateAndSignInUser)}
          >
            {isError && (
              <Box>
                <ErrorIcon color="error" />
                <Typography color="error">{"error"}</Typography>
              </Box>
            )}
            {showSuccess && (
              <Box>
                <Alert
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="success"
                >
                  Successfully send reset password link to your email.
                </Alert>
              </Box>
            )}
            <FormControl margin="normal" required fullWidth>
              <TextField
                name="email"
                label="Email"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <Link to="/signin">
                <Typography variant="body2" color="initial" gutterBottom>
                  Back to Signin
                </Typography>
              </Link>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSend}
              disabled={isLoading}
            >
              SEND RESET LINK TO EMAIL
            </Button>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  );
}
