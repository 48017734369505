import { useInfiniteHits } from "react-instantsearch";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

export default function ReportsVarietyList(props: any) {
  const { items, isLastPage, showMore } = useInfiniteHits(props);

  const sentinelRef = React.useRef(null);

  React.useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            // Load more hits
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  const { checked, toggleChecked } = props;
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Supplier
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Commodity
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Growers
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Shippers
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Regions
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row: any) => (
            <TableRow key={row.objectID}>
              <TableCell>
                <Link to={`/reports/single/${row.objectID}`}>
                  <Typography variant="body2">{row.name}</Typography>
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{row.company.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {row.commodities.join(", ")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {row.growers.join(", ")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {row.shippers.join(", ")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {row.regions.join(", ")}
                </Typography>
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={checked.indexOf(row.objectID) !== -1}
                  onClick={() => toggleChecked(row.objectID)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div ref={sentinelRef} aria-hidden="true" />
    </Box>
  );
}
