import { EvaluableType, FieldVariety, TrialVariety } from "../../types";
import {
  Card,
  CardContent,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import EvaluationApi from "../../api/Evaluation.api";

export default function EvaluationReportCard({
  typeVariety,
  evaluableType,
  color,
}: {
  typeVariety: FieldVariety | TrialVariety;
  evaluableType: EvaluableType;
  color?: string;
}) {
  const { data: evaluations } = EvaluationApi.useList({
    evaluableType,
    evaluableId: typeVariety.id,
  });
  return (
    <Card style={{ backgroundColor: color }}>
      <CardContent>
        <Typography variant="subtitle1">Evaluation</Typography>
        {evaluations &&
          evaluations
            .sort((a, b) => {
              let locationA = a.criterium.location;
              let locationB = b.criterium.location;
              if (locationA < locationB) {
                return -1;
              } else if (locationA > locationB) {
                return 1;
              } else {
                return 0;
              }
            })
            .map(e => (
              <ListItem key={e.id} sx={{ p: 0, m: 0 }}>
                <ListItemText
                  sx={{ p: 0, m: 0 }}
                  secondary={`${e.criterium.name} : ${e.subcriterium.name}`}
                />
              </ListItem>
            ))}
      </CardContent>
    </Card>
  );
}
