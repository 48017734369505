import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import CriteriumForm, { DragPreview } from "./CriteriumForm";
import CommodityLabelForm from "./commodities/CommodityLabelForm";
import LabelSelectList from "./commodities/LabelSelectList";
import { useCommodityContext } from "../contexts/Commodity.context";
import DragAndDropList from "./DragAndDropList";

export default function CommodityForm() {
  const navigate = useNavigate();
  const {
    maxScore,
    commodity,
    setCommodity,
    criteria,
    labels,
    handleSaveCommodity,
    handleDeleteCommodity,
    handleCreateCriterium,
    handleSaveCriterium,
  } = useCommodityContext();

  const [showLabels, setShowLabels] = React.useState<boolean>(false);

  const onSave = async () => {
    const res = handleSaveCommodity && (await handleSaveCommodity());
    if (res) {
      navigate(`/commodities/${res.id}`);
    }
  };

  const handleDelete = async () => {
    const res = handleDeleteCommodity && (await handleDeleteCommodity());
    if (res) {
      navigate(`/commodities`);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Typography variant="h2" gutterBottom>
          {commodity?.id ? "Edit Commodity" : "Create Commodity"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          color={"primary"}
          variant="contained"
          onClick={() => setShowLabels(l => !l)}
        >
          {showLabels ? "Hide Labels" : "Add Label"}
        </Button>
      </Grid>

      {showLabels && commodity?.id ? (
        <Grid item xs={12}>
          <LabelSelectList
            commodityId={commodity?.id}
            handleClose={() => setShowLabels(false)}
            attachedLabels={labels}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={9}>
            <TextField
              fullWidth
              value={commodity?.name || ""}
              label="Name"
              onChange={event =>
                setCommodity &&
                setCommodity(c => c && { ...c, name: event.target.value })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant={"outlined"}
              fullWidth
              value={maxScore}
              disabled={true}
              label="Max Score"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {labels &&
                commodity &&
                labels.map(label => (
                  <Grid item xs={4} key={label.id}>
                    <Paper sx={{ p: "1rem" }}>
                      <CommodityLabelForm commodity={commodity} label={label} />
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              {criteria && handleSaveCriterium && (
                <DragAndDropList
                  items={Object.values(criteria)}
                  updateItem={handleSaveCriterium}
                  TypeItem={CriteriumForm}
                  DragPreview={DragPreview}
                  isTaskData={(obj: any) => !!obj?.commodityId}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={onSave}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant={"contained"}
              color={"error"}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant={"contained"}
              color={"info"}
              onClick={handleCreateCriterium}
            >
              Add Criterium
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}
