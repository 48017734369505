import React from "react";
import PersonApi from "../api/Person.api";
import { Person } from "../types";
import { Autocomplete, TextField } from "@mui/material";

type Option = {
  label: string;
  value: Person;
};

export default function AssociatedPeople({
  selectedPeople,
  setSelectedPeople,
}: {
  selectedPeople: Person[];
  setSelectedPeople: (arg: any[]) => void;
}) {
  const [inputValue, setInputValue] = React.useState<string>("");
  const [value, setValue] = React.useState<Option[]>(
    selectedPeople.map(person => ({
      label: `${person.firstName} ${person.lastName}`,
      value: person,
    })) || []
  );
  const { data } = PersonApi.useList({ query: inputValue });
  const options = data
    ? data.data.map(person => ({
        label: `${person.firstName} ${person.lastName}`,
        value: person,
      }))
    : [];

  return (
    <Autocomplete
      autoHighlight
      multiple
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option: Option, value: Option) => {
        return option.value.id === value.value.id;
      }}
      options={options}
      filterOptions={(options: Option[]) =>
        options.filter(
          option => !value.find(v => v.value.id === option.value.id)
        )
      }
      value={value}
      onChange={(event: any, newValues: Option[]) => {
        setValue(newValues);
        setSelectedPeople(newValues.map(option => option.value));
      }}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          label="Select People"
          variant="outlined"
          placeholder={"People"}
        />
      )}
    />
  );
}
