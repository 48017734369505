import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";

export default function DeleteConfirmation({
  id,
  useDelete,
  question,
}: {
  id: number;
  useDelete: (arg: number) => any;
  question?: string;
}) {
  const { mutateAsync, isLoading } = useDelete(id);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleConfirm = async () => {
    await onDelete();
    setOpen(false);
  };

  const onDelete = async () => {
    try {
      await mutateAsync();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Button
        color={"error"}
        variant="text"
        size="small"
        aria-label="Delete"
        onClick={() => setOpen(true)}
      >
        <DeleteIcon />
      </Button>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {question || "Are you sure you want to delete the following?"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            onClick={handleConfirm}
            color="primary"
            variant={"contained"}
          >
            Confirm
          </Button>
          <Button onClick={() => setOpen(false)} variant={"outlined"}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
