import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useInfiniteHits } from "react-instantsearch";
import { Variety } from "../types";
import React from "react";

export default function AlgoliaVarietyCombine(props: any) {
  const { selectedItems, setSelectedItems, variety } = props;
  const { items, isLastPage, showMore } = useInfiniteHits(props);
  const sentinelRef = React.useRef(null);

  React.useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            // Load more hits
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  const handleChange =
    (objectID: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedItems((checked: { [key: string]: boolean }) => {
        checked[objectID] = event.target.checked;
        return checked;
      });
    };

  const combinedVarietyIds =
    variety?.experimentalVarieties.map((expVariety: Variety) =>
      expVariety.id.toString()
    ) || [];
  combinedVarietyIds.push(variety?.id.toString());

  return (
    <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Supplier
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Commodity
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items
            .filter((row: any) => !combinedVarietyIds.includes(row.objectID))
            .map((row: any) => (
              <TableRow key={row.objectID}>
                <TableCell>
                  <Typography variant="body2" gutterBottom>
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" gutterBottom>
                    {row.company.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" gutterBottom>
                    {row.commodities.join(", ")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={selectedItems[row.objectID]}
                    onChange={handleChange(row.objectID)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div ref={sentinelRef} aria-hidden="true" />
    </Grid>
  );
}
