import { useInfiniteHits, Highlight } from "react-instantsearch";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import React from "react";
import { useSession } from "../contexts/Session.context";
import UserApi from "../api/User.api";

export default function AlgoliaTrialList(props: any) {
  const { session } = useSession();
  const { data: user } = UserApi.useDetail(session && session?.userId);
  const { items, isLastPage, showMore } = useInfiniteHits(props);
  const sentinelRef = React.useRef(null);

  React.useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            // Load more hits
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Commodity
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Wet Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Est. Harvest Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Grower
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Region
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Area
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Ranch
              </Typography>
            </TableCell>
            {user && !user.hideExtraTrialInfo && (
              <React.Fragment>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Map Printed
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Producer Map Emailed
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Grower Map Emailed
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Cleaned
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Evaluation Ready
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Producer Evaluation Emailed
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Grower Evaluation Emailed
                  </Typography>
                </TableCell>
              </React.Fragment>
            )}
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Trial Type
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row: any) => (
            <TableRow key={row.objectID}>
              <TableCell>
                <Link to={`/trials/${row?.objectID}`}>
                  <Typography variant="body2" gutterBottom>
                    <Highlight attribute={"name"} hit={row} />
                  </Typography>
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  {row?.commodity?.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  {row.wet_date}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  {row.harvest_date}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  {row?.grower?.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  {row?.region?.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  {row?.area?.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  {row?.ranch?.name}
                </Typography>
              </TableCell>
              {user && !user.hideExtraTrialInfo && (
                <React.Fragment>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {row.map_printed ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {row.producer_map_emailed ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {row.grower_map_emailed ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {row.cleaned ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {row.evaluation_ready ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {row.producer_evaluation_emailed ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {row.grower_evaluation_emailed ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </Typography>
                  </TableCell>
                </React.Fragment>
              )}
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  {row.sales_team ? "Sales Trial" : "PD Trial"}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div ref={sentinelRef} aria-hidden="true" />
    </Grid>
  );
}
