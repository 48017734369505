import { Label, Tag, TagInput } from "../types";
import React from "react";
import TagApi from "../api/Tag.api";
import LabelApi from "../api/Label.api";
import { HttpError } from "../types/http-error";
import toast from "react-hot-toast";

type Props = {
  id?: string;
  children: JSX.Element;
};

interface ILabelContext {
  label?: Label;
  setLabel: React.Dispatch<React.SetStateAction<Label | undefined>>;
  tags: { [key: number]: Tag };
  setTags: React.Dispatch<React.SetStateAction<{ [key: number]: Tag }>>;
  isLoadingSaveLabel: boolean;
  handleSaveLabel: () => Promise<Label | undefined>;
  handleDeleteLabel: () => Promise<boolean>;
  handleCreateTag: () => Promise<Tag | undefined>;
  handleSaveTag: (input: Tag) => Promise<Tag | undefined>;
  handleDeleteTag: (tagId: number) => Promise<boolean>;
}

const LabelContext = React.createContext<Partial<ILabelContext>>({});

export function LabelProvider({ id, children }: Props) {
  const [label, setLabel] = React.useState<Label | undefined>();
  const [tags, setTags] = React.useState<{ [key: number]: Tag }>({});

  const { data: labelData, isFetched: labelIsFetched } = LabelApi.useDetail(id);
  const { data: tagData, isFetched: tagsIsFetched } = TagApi.useList(id);

  // LABEL
  const { mutateAsync: saveLabel, isLoading: isLoadingSaveLabel } =
    LabelApi.useSave({
      ...label,
      tags: Object.values(tags).sort((a, b) => a.location - b.location),
    });
  const { mutateAsync: deleteLabel } = LabelApi.useDelete(id);

  // TAGS
  const { mutateAsync: saveTag } = TagApi.useSave();
  const { mutateAsync: deleteTag } = TagApi.useDelete();

  React.useEffect(() => {
    if (labelData && labelIsFetched) {
      setLabel(labelData);
    }
  }, [labelData, labelIsFetched]);

  React.useEffect(() => {
    if (tagData && tagsIsFetched) {
      setTags(tags => {
        tagData.forEach((tag: Tag) => {
          tags[tag.id] = tag;
        });
        return { ...tags };
      });
    }
  }, [tagData, tagsIsFetched]);

  const handleSaveLabel = async (): Promise<Label | undefined> => {
    try {
      return await saveLabel();
      // TODO
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to save Label");
      }
      return undefined;
    }
  };

  const handleDeleteLabel = async () => {
    try {
      await deleteLabel();
      return true;
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to delete Label");
      }
      return false;
    }
  };

  const handleCreateTag = async (): Promise<Tag | undefined> => {
    try {
      if (!label) {
        return undefined;
      }
      const newTag = await saveTag({ labelId: label.id, name: "" });
      if (newTag) {
        setTags(tags => ({ ...tags, [newTag.id]: newTag }));
      }
      return newTag;
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Tag");
      }
      return undefined;
    }
  };

  const handleSaveTag = async (
    tagInput: TagInput
  ): Promise<Tag | undefined> => {
    try {
      const tag = await saveTag(tagInput);
      if (tag) {
        setTags(tags => ({ ...tags, [tag.id]: tag }));
      }
      return tag;
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to save Tag");
      }
      return undefined;
    }
  };

  const handleDeleteTag = async (tagId: number): Promise<boolean> => {
    try {
      if (!id) {
        return false;
      }
      await deleteTag({ id: tagId, labelId: id });
      setTags(tags => {
        delete tags[tagId];
        return { ...tags };
      });
      return true;
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to delete Tag");
      }
      return false;
    }
  };

  return (
    <LabelContext.Provider
      value={{
        label,
        setLabel,
        tags,
        isLoadingSaveLabel,
        handleSaveLabel,
        handleDeleteLabel,
        handleCreateTag,
        handleSaveTag,
        handleDeleteTag,
      }}
    >
      {children}
    </LabelContext.Provider>
  );
}

export function useLabelContext() {
  return React.useContext(LabelContext);
}
