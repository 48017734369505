import { Autocomplete, TextField } from "@mui/material";
import { Area } from "../types";
import React from "react";
import AreaApi from "../api/Area.api";

type Option = {
  label: string;
  value: Area;
};

export default function AreaSelect({
  area,
  setArea,
  label,
}: {
  area?: Area;
  setArea: (arg: Area) => void;
  label: string;
}) {
  const [value, setValue] = React.useState<Option | null>(
    area ? { label: area.name, value: area } : null
  );
  const [inputValue, setInputValue] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const { data, isFetching } = AreaApi.useList({ query: inputValue });

  const options: Option[] = data
    ? data.data.map(area => ({ label: area.name, value: area }))
    : [];
  return (
    <Autocomplete
      autoHighlight
      loading={isFetching}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event: any, newValue: Option | null) => {
        setValue(newValue);
        setArea(newValue?.value as Area);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={params => <TextField {...params} label={label} />}
    />
  );
}
