import {Box, Button, Typography} from "@mui/material";
import {useReportCompareContext} from "../../contexts/ReportCompare.context";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";

export default function ReportCompareHeader(){
  const { mainVarieties } = useReportCompareContext()
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
      <Box sx={{ flex: 1 }}>
        {mainVarieties && Object.values(mainVarieties).map(variety => (
          <Typography key={variety.id} variant={"h3"}>{variety.name}</Typography>
        ))}
      </Box>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}><Button variant={"contained"} color={"primary"} size={"large"}><EmailIcon /></Button></Box>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}><Button variant={"contained"} color={"primary"} size={"large"}><DescriptionIcon /></Button></Box>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}><Button variant={"contained"} color={"primary"} size={"large"}><PrintIcon /></Button></Box>
      </Box>
    </Box>
  )
}