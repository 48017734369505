import { FieldVariety, PlotType, Trial, Variety } from "../../types";
import VarietySelect from "../VarietySelect";
import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import FieldVarietyApi from "../../api/FieldVariety.api";
import LabelIcon from "@mui/icons-material/Label";
import AttachTrialVarietyToFieldVariety from "./AttachTrialVarietyToFieldVariety";
import SelectedTypeVariety from "./SelectedTypeVariety";

export default function AttachFieldVarietyToTrial({
  trial,
  plotType,
  plotRows,
  plotCols,
}: {
  trial: Trial;
  plotType: PlotType;
  plotRows: number;
  plotCols: number;
}) {
  const { data: fieldVarieties } = FieldVarietyApi.useList(trial.id);
  const [selectedFieldVariety, setSelectedFieldVariety] = React.useState<
    FieldVariety | undefined
  >(undefined);
  const [variety, setVariety] = React.useState<Variety | undefined>(undefined);

  const { mutateAsync: createFieldVariety } = FieldVarietyApi.useSave();
  const { mutateAsync: deleteFieldVariety } = FieldVarietyApi.useDelete();

  const handleDeleteFieldVariety = (fieldVariety: FieldVariety) => async () => {
    try {
      await deleteFieldVariety(fieldVariety);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFieldVarietySelect = (fieldVariety: FieldVariety) => () => {
    setSelectedFieldVariety(fieldVariety);
  };

  const handleAttachFieldVariety = async (variety: Variety) => {
    try {
      setVariety(variety);
      if (variety) {
        await createFieldVariety({
          varietyId: variety.id,
          trialId: trial.id,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setSelectedFieldVariety(undefined);
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ flex: 1 }}>
          <VarietySelect
            setVariety={handleAttachFieldVariety}
            variety={variety}
            label={"Add Field Variety"}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          {/* show all the field varieties for the trial */}
          {fieldVarieties &&
            fieldVarieties
              .sort((a, b) => a.id - b.id)
              .map(fieldVariety => (
                <Chip
                  key={fieldVariety.id}
                  onClick={handleFieldVarietySelect(fieldVariety)}
                  variant={
                    selectedFieldVariety?.id === fieldVariety.id
                      ? "filled"
                      : "outlined"
                  }
                  label={fieldVariety.variety.name}
                  onDelete={handleDeleteFieldVariety(fieldVariety)}
                  avatar={<LabelIcon />}
                />
              ))}
        </Box>
      </Box>
      <Box>
        {selectedFieldVariety && (
          <SelectedTypeVariety
            typeVariety={selectedFieldVariety}
            evaluableType={"field_variety"}
            useSave={FieldVarietyApi.useSave}
            handleClose={handleClose}
          >
            <>
              <Typography variant={"h5"}>
                {selectedFieldVariety.variety.name}
              </Typography>
              <Typography variant={"h5"}>Field Variety Evaluation</Typography>
              <Box>
                <AttachTrialVarietyToFieldVariety
                  fieldVariety={selectedFieldVariety}
                  plotType={plotType}
                  plotRows={plotRows}
                  plotCols={plotCols}
                />
              </Box>
            </>
          </SelectedTypeVariety>
        )}
      </Box>
    </>
  );
}
