import React from "react";
import { useNavigate } from "react-router-dom";

import RegionApi from "../api/Region.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import PageHeader from "../ui/PageHeader";
import { Region } from "../types";

export default function RegionList() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader title={"Regions"} half={6}>
        <IndexHeaderButton
          handleCreate={() => {
            navigate("/regions/create");
          }}
        />
      </PageHeader>
      <SimpleListName
        useList={RegionApi.useInfiniteList}
        useDelete={RegionApi.useDelete}
        baseUrl="regions"
        headers={[
          { key: "name", label: "Name", getValue: (obj: Region) => obj.name },
        ]}
      />
    </>
  );
}
