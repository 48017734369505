import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Criterium,
  EvaluableType,
  Evaluation,
  Subcriterium,
} from "../../types";
import React from "react";
import EvaluationApi from "../../api/Evaluation.api";
import { isEmpty } from "lodash";

export default function EvaluationComponent({
  criterium,
  evaluation,
  evaluableId,
  evaluableType,
}: {
  criterium: Criterium;
  evaluation?: Evaluation;
  evaluableId: number;
  evaluableType: EvaluableType;
}) {
  const [subcriterium, setSubcriterium] = React.useState<
    Subcriterium | undefined
  >(evaluation?.subcriterium);

  const { mutateAsync: saveEvaluation } = EvaluationApi.useSave();

  const handleChange = async (event: SelectChangeEvent<number>) => {
    const subcriteriumId = event.target.value as number;
    const selectedSubcriterium = criterium.subcriteria.find(
      subcriterium => subcriterium.id === subcriteriumId
    );
    setSubcriterium(selectedSubcriterium);
    try {
      await saveEvaluation({
        evaluationInput: {
          id: evaluation?.id,
          subcriteriumId: selectedSubcriterium?.id,
          criteriumId: criterium.id,
          commodityId: criterium.commodityId,
        },
        evaluableType,
        evaluableId,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="select-evaluation-label">{criterium.name}</InputLabel>
      <Select
        fullWidth
        labelId="select-evaluation-label"
        id="select-evaluation"
        label={criterium.name}
        value={subcriterium?.id}
        onChange={handleChange}
      >
        {/* TODO: sort this by location */}
        {criterium &&
          !isEmpty(criterium.subcriteria) &&
          criterium.subcriteria
            .sort((a, b) => a && b && a.location - b.location)
            .map((subcriterium: Subcriterium) => (
              <MenuItem value={subcriterium.id} key={subcriterium.id}>
                {subcriterium.name}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
}
