import { handleSimpleList } from "./handler";
import { PlotBed, PlotBedGrandParentType } from "../types";
import { useQuery } from "react-query";

const methods = {
  useList: ({
    parentType,
    parentId,
  }: {
    parentType: PlotBedGrandParentType;
    parentId?: number | string;
  }) => {
    const paramKey = parentType === "nursery" ? "nursery_id" : "trial_id";
    return useQuery<PlotBed[]>({
      queryKey: ["plot_beds", parentType, parentId?.toString()],
      queryFn: () =>
        handleSimpleList({
          url: `plot_beds`,
          params: [{ key: paramKey, value: parentId }],
        }),
      enabled: !!parentId,
    });
  },
};
export default methods;
