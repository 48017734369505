import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Trial, TrialVariety, Variety } from "../../types";
import { capitalize, uniqBy } from "lodash";
import dayjs from "dayjs";
import FieldVarietyApi from "../../api/FieldVariety.api";
import TrialVarietyApi from "../../api/TrialVariety.api";

export default function ReportHeader({
  trial,
  supplierId,
  setSupplierId,
}: {
  trial: Trial;
  supplierId?: number;
  setSupplierId: (arg?: number) => void;
}) {
  const { data: trialVarieties } = TrialVarietyApi.useList(trial.id);
  const { data: fieldVarieties } = FieldVarietyApi.useList(trial.id);
  console.log('trialVarieties', trialVarieties)
  return (
    <>
      <Grid item xs={12}>
        {trialVarieties && (
          <FormControl variant={"standard"} sx={{ width: "20rem" }}>
            <InputLabel id="supplier-select-label">Supplier</InputLabel>
            <Select
              labelId="supplier-select-label"
              id="supplier-select"
              value={supplierId}
              label="Supplier"
              onChange={event => {
                const value = event.target.value;
                if (value) {
                  setSupplierId(event.target.value as number);
                } else {
                  setSupplierId(undefined);
                }
              }}
            >
              <MenuItem value={undefined}>None</MenuItem>
              {uniqBy(
                trialVarieties.map(
                  (trialVariety: TrialVariety) => trialVariety.variety
                ),
                "companyId"
              ).map((variety: Variety) => (
                <MenuItem
                  key={variety?.company?.id}
                  value={variety?.company?.id}
                >
                  {variety?.company?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid item xs={4}>
        <Typography variant={"body1"}>
          Evaluator: {trial?.user?.name}
        </Typography>
        <Typography variant={"body1"}>
          Commodity: {trial?.commodity?.name}
        </Typography>
        <Typography variant={"body1"}>
          Shipper: {trial?.shipper?.name}
        </Typography>
        <Typography variant={"body1"}>
          Field Varieties:{" "}
          {fieldVarieties &&
            fieldVarieties
              ?.map(fieldVariety => fieldVariety.variety.name)
              .join(", ")}
        </Typography>
        <Typography variant={"body1"}>
          Cultural Practice: {capitalize(trial?.culturalPractice)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant={"body1"}>Grower: {trial?.grower?.name}</Typography>
        <Typography variant={"body1"}>
          SubGrower: {trial?.subGrower?.name}
        </Typography>
        <Typography variant={"body1"}>City: {trial?.region?.name}</Typography>
        <Typography variant={"body1"}>Ranch: {trial?.ranch?.name}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant={"body1"}>Lot: {trial?.lot}</Typography>
        <Typography variant={"body1"}>
          Wet Date:{" "}
          {trial?.wetDate && dayjs(trial?.wetDate).format("MMM D, YYYY")}
        </Typography>
        <Typography variant={"body1"}>
          Harvest Date:{" "}
          {trial?.estHarvestDate &&
            dayjs(trial?.estHarvestDate).format("MMM D, YYYY")}
        </Typography>
        <Typography variant={"body1"}>
          Lng: {trial?.lng?.slice(0, -8)}
        </Typography>
        <Typography variant={"body1"}>
          Lat: {trial?.lat?.slice(0, -8)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>{trial?.comments}</Typography>
      </Grid>
    </>
  );
}
