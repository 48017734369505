import { Commodity, EvaluableType } from "../../types";
import CriteriumApi from "../../api/Criterium.api";
import { Grid } from "@mui/material";
import Evaluation from "./Evaluation";
import EvaluationApi from "../../api/Evaluation.api";

export default function Evaluations({
  commodity,
  evaluableId,
  evaluableType,
}: {
  commodity: Commodity;
  evaluableId: number;
  evaluableType: EvaluableType;
}) {
  const { data: criteria } = CriteriumApi.useList(commodity.id);

  const { data: evaluations } = EvaluationApi.useList({
    evaluableType,
    evaluableId,
  });

  return (
    <>
      {criteria &&
        evaluations &&
        criteria.map(criterium => (
          <Grid item xs={4} md={3} key={criterium.id}>
            <Evaluation
              evaluableId={evaluableId}
              evaluableType={evaluableType}
              criterium={criterium}
              key={criterium.id}
              evaluation={evaluations.find(
                evaluation =>
                  evaluation.commodityId === criterium.commodityId &&
                  evaluation.criteriumId === criterium.id
              )}
            />
          </Grid>
        ))}
    </>
  );
}
