import LockIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import SessionApi from "../api/Session.api";
import { useSession } from "../contexts/Session.context";
import toast from "react-hot-toast";
import { HttpError } from "../types/http-error";

export default function SignIn() {
  const theme = useTheme();
  const { setSession } = useSession();

  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  const navigate = useNavigate();

  const { isLoading, mutateAsync } = SessionApi.useLogin(email, password);

  const handleSignIn = async () => {
    try {
      const res = await mutateAsync();
      setSession && setSession(res);
      navigate("/");
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Invalid email & password");
      }
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          width: "auto",
          display: "block", // Fix IE11 issue.
          marginLeft: theme.spacing(3),
          marginRight: theme.spacing(3),
          paddingTop: theme.spacing(10),
          [theme.breakpoints.up(400)]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: theme.spacing(2, 3, 3),
          }}
          elevation={2}
        >
          <Avatar
            sx={{
              margin: theme.spacing(1),
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="subtitle1" color="initial">
            Sign in
          </Typography>
          <Box
            sx={{
              width: "100%", // Fix IE11 issue.
              marginTop: theme.spacing(1),
            }}
            // onSubmit={this.props.handleSubmit(this.validateAndSignInUser)}
          >
            <FormControl margin="normal" required fullWidth>
              <TextField
                name="email"
                label="Email"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextField
                name="password"
                label="Password"
                type="password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                }}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <Link to="/forgot_password">
                <Typography variant="body2" color="initial" gutterBottom>
                  Forgot your password?
                </Typography>
              </Link>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSignIn}
              // className={classes.submit}
              disabled={isLoading}
            >
              Sign in
            </Button>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  );
}
