import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import VarietyApi from "../api/Variety.api";
import PageHeader from "../ui/PageHeader";
import AlgoliaSearchBox from "../components/AlgoliaSearchBox";
import AlgoliaVarietyCombine from "../components/AlgoliaVarietyCombine";

import { liteClient } from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import { Button } from "@mui/material";

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function VarietyCombine() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: variety } = VarietyApi.useDetail(id);
  const [checked, setChecked] = React.useState<{ [key: string]: boolean }>({});

  const { mutateAsync } = VarietyApi.useCombine(id);

  const handleCombine = async () => {
    try {
      await mutateAsync(Object.keys(checked).filter(key => checked[key]));
      navigate(`/varieties/${id}`);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <PageHeader title={`Variety ${variety?.name} Combine`} half={6}>
        <Button onClick={handleCombine}>Combine</Button>
      </PageHeader>
      <InstantSearch
        searchClient={searchClient}
        indexName={`Variety_${process.env.NODE_ENV}`}
      >
        <AlgoliaSearchBox />
        <AlgoliaVarietyCombine
          variety={variety}
          selectedItems={checked}
          setSelectedItems={setChecked}
        />
      </InstantSearch>
    </>
  );
}
