import React from "react";
import {
  Area,
  ColumnType,
  Company,
  FieldVariety,
  Region,
  TrialVariety,
  Variety,
} from "../types";
import VarietyApi from "../api/Variety.api";
import TrialVarietyApi from "../api/TrialVariety.api";
import FieldVarietyApi from "../api/FieldVariety.api";
import { isEmpty, isNil, unionBy } from "lodash";
import dayjs, { Dayjs } from "dayjs";

type Props = {
  varietyIds: string[];
  children: JSX.Element;
};

interface IReportCompareContext {
  mainVarieties: { [key: number]: Variety };
  varieties: { [key: number]: Variety };
  trialVarieties: TrialVariety[];
  fieldVarieties: FieldVariety[];
  filteredCombinedVarieties: ColumnType[];
  displayGrower: boolean;
  setDisplayGrower: React.Dispatch<React.SetStateAction<boolean>>;
  displayRanch: boolean;
  setDisplayRanch: React.Dispatch<React.SetStateAction<boolean>>;
  displayShipper: boolean;
  setDisplayShipper: React.Dispatch<React.SetStateAction<boolean>>;
  displaySupplier: boolean;
  setDisplaySupplier: React.Dispatch<React.SetStateAction<boolean>>;
  filterConventional: boolean;
  setFilterConventional: React.Dispatch<React.SetStateAction<boolean>>;
  filterOrganic: boolean;
  setFilterOrganic: React.Dispatch<React.SetStateAction<boolean>>;
  filterGrowersLike: boolean;
  setFilterGrowersLike: React.Dispatch<React.SetStateAction<boolean>>;
  excludeYears: boolean;
  setExcludeYears: React.Dispatch<React.SetStateAction<boolean>>;
  searchByWetDate: boolean;
  setSearchByWetDate: React.Dispatch<React.SetStateAction<boolean>>;
  commodityOptionsSelected: { [key: string]: boolean };
  setCommodityOptionsSelected: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  supplierOptionsSelected: { [key: string]: boolean };
  setSupplierOptionsSelected: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  filteredAreas: Area[];
  setFilteredAreas: React.Dispatch<React.SetStateAction<Area[]>>;
  filteredGrowers: Company[];
  setFilteredGrowers: React.Dispatch<React.SetStateAction<Company[]>>;
  filteredShippers: Company[];
  setFilteredShippers: React.Dispatch<React.SetStateAction<Company[]>>;
  filteredRegions: Region[];
  setFilteredRegions: React.Dispatch<React.SetStateAction<Region[]>>;
  startDate: Dayjs | null;
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  endDate: Dayjs | null;
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
}

const ReportCompareContext = React.createContext<
  Partial<IReportCompareContext>
>({});

export function ReportCompareProvider({ varietyIds, children }: Props) {
  const [mainVarieties, setMainVarieties] = React.useState<{
    [key: number]: Variety;
  }>({});
  const [varieties, setVarieties] = React.useState<{ [key: number]: Variety }>(
    {}
  );
  const [fieldVarieties, setFieldVarieties] = React.useState<FieldVariety[]>(
    []
  );
  const [fieldVarietyPage, setFieldVarietyPage] = React.useState<number>(1);
  const [trialVarieties, setTrialVarieties] = React.useState<TrialVariety[]>(
    []
  );
  const [trialVarietyPage, setTrialVarietyPage] = React.useState<number>(1);
  const [displayGrower, setDisplayGrower] = React.useState<boolean>(true);
  const [displayShipper, setDisplayShipper] = React.useState<boolean>(true);
  const [displaySupplier, setDisplaySupplier] = React.useState<boolean>(true);
  const [displayRanch, setDisplayRanch] = React.useState<boolean>(true);
  const [filterConventional, setFilterConventional] =
    React.useState<boolean>(true);
  const [filterOrganic, setFilterOrganic] = React.useState<boolean>(true);
  const [filterGrowersLike, setFilterGrowersLike] =
    React.useState<boolean>(false);
  const [excludeYears, setExcludeYears] = React.useState<boolean>(false);
  const [searchByWetDate, setSearchByWetDate] = React.useState<boolean>(true);
  const [commodityOptionsSelected, setCommodityOptionsSelected] =
    React.useState<{ [key: string]: boolean }>({});
  const [supplierOptionsSelected, setSupplierOptionsSelected] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [filteredAreas, setFilteredAreas] = React.useState<Area[]>([]);
  const [filteredGrowers, setFilteredGrowers] = React.useState<Company[]>([]);
  const [filteredShippers, setFilteredShippers] = React.useState<Company[]>([]);
  const [filteredRegions, setFilteredRegions] = React.useState<Region[]>([]);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);

  const { data: varietyData, isFetched: varietyDataIsFetched } =
    VarietyApi.useListWithExpiermentalVarieties(varietyIds);
  const { data: fieldVarietiesData, isFetched: fieldVarietyDataIsFetched } =
    FieldVarietyApi.useListByVarietyIds({
      varietyIds: varietyIds,
      page: fieldVarietyPage,
    });
  const { data: trialVarietiesData, isFetched: trialVarietyDataIsFetched } =
    TrialVarietyApi.useListByVarietyIds({
      varietyIds: varietyIds,
      page: trialVarietyPage,
    });

  React.useEffect(() => {
    if (varietyData && varietyDataIsFetched) {
      setVarieties(varieties => {
        varietyData.forEach(variety => {
          varieties[variety.id] = variety;
        });
        return { ...varieties };
      });
      setMainVarieties(mainVarieties => {
        varietyData.forEach(variety => {
          if (varietyIds.includes(variety.id?.toString())) {
            mainVarieties[variety.id] = variety;
          }
        });
        return { ...mainVarieties };
      });
    }
  }, [varietyData, varietyDataIsFetched, varietyIds]);

  React.useEffect(() => {
    if (fieldVarietiesData && fieldVarietyDataIsFetched) {
      setFieldVarieties(fieldVarieties =>
        unionBy(fieldVarieties, fieldVarietiesData.data, "id")
      );
      if (fieldVarietiesData.meta.nextPage) {
        setFieldVarietyPage(fieldVarietiesData.meta.nextPage);
      }
      fieldVarietiesData.data.forEach(typeVariety => {
        typeVariety.variety.commodities.forEach(commodity => {
          setCommodityOptionsSelected(coptions => {
            if (!coptions[commodity.id]) {
              coptions[commodity.id?.toString()] = true;
              return { ...coptions };
            }
            return coptions;
          });
        });
      });
      fieldVarietiesData.data.forEach(typeVariety => {
        const supplier = typeVariety.variety.company;
        setSupplierOptionsSelected(options => {
          if (!options[supplier.id]) {
            options[supplier.id?.toString()] = true;
            return { ...options };
          }
          return options;
        });
      });
    }
  }, [fieldVarietiesData, fieldVarietyDataIsFetched]);

  React.useEffect(() => {
    if (trialVarietiesData && trialVarietyDataIsFetched) {
      setTrialVarieties(trialVarieties =>
        unionBy(trialVarieties, trialVarietiesData.data, "id")
      );
      if (trialVarietiesData.meta.nextPage) {
        setTrialVarietyPage(trialVarietiesData.meta.nextPage);
      }
      trialVarietiesData.data.forEach(typeVariety => {
        typeVariety.variety.commodities.forEach(commodity => {
          setCommodityOptionsSelected(coptions => {
            if (isNil(coptions[commodity.id])) {
              coptions[commodity.id?.toString()] = true;
              return { ...coptions };
            }
            return coptions;
          });
        });
      });
      trialVarietiesData.data.forEach(typeVariety => {
        const supplier = typeVariety.variety.company;
        setSupplierOptionsSelected(options => {
          if (isNil(options[supplier.id])) {
            options[supplier.id?.toString()] = true;
            return { ...options };
          }
          return options;
        });
      });
    }
  }, [trialVarietiesData, trialVarietyDataIsFetched]);

  let filteredCombinedVarieties: ColumnType[] = [];
  trialVarieties?.forEach(trialVariety =>
    filteredCombinedVarieties.push({
      evaluableType: "trial_variety",
      typeVariety: trialVariety,
    })
  );
  fieldVarieties?.forEach(fieldVariety =>
    filteredCombinedVarieties.push({
      evaluableType: "field_variety",
      typeVariety: fieldVariety,
    })
  );
  filteredCombinedVarieties = filteredCombinedVarieties.filter(columnType => {
    if (
      isNil(columnType.typeVariety.comments) ||
      columnType.typeVariety.comments.length === 0
    ) {
      return false;
    }
    if (
      isNil(columnType.typeVariety.growerComments) ||
      columnType.typeVariety.growerComments.length === 0
    ) {
      return false;
    }
    if (!columnType.typeVariety.growerLike && filterGrowersLike) {
      return false;
    }
    if (
      !filterOrganic &&
      columnType.typeVariety.trial.culturalPractice === "organic"
    ) {
      return false;
    }
    if (
      !filterConventional &&
      columnType.typeVariety.trial.culturalPractice === "conventional"
    ) {
      return false;
    }

    if (startDate) {
      const date = searchByWetDate
        ? columnType.typeVariety.trial.wetDate
        : columnType.typeVariety.trial.estHarvestDate;
      if (excludeYears) {
        if (isNil(date) || dayjs(date).dayOfYear() < startDate.dayOfYear()) {
          return false;
        }
      } else {
        if (isNil(date) || dayjs(date) < startDate) {
          return false;
        }
      }
    }
    if (endDate) {
      const date = searchByWetDate
        ? columnType.typeVariety.trial.wetDate
        : columnType.typeVariety.trial.estHarvestDate;
      if (excludeYears) {
        if (isNil(date) || dayjs(date).dayOfYear() > endDate.dayOfYear()) {
          return false;
        }
      } else {
        if (isNil(date) || dayjs(date) > endDate) {
          return false;
        }
      }
    }

    if (supplierOptionsSelected && !isEmpty(supplierOptionsSelected)) {
      const supplierId = columnType?.typeVariety?.variety?.companyId;
      const selectedSupplierIds = Object.keys(supplierOptionsSelected).filter(
        key => supplierOptionsSelected[key]
      );
      if (
        isNil(supplierId) ||
        !selectedSupplierIds.includes(supplierId?.toString())
      ) {
        return false;
      }
    }
    if (commodityOptionsSelected && !isEmpty(commodityOptionsSelected)) {
      const commodityId = columnType?.typeVariety?.commodityId;
      const selectedCommodityIds = Object.keys(commodityOptionsSelected).filter(
        key => commodityOptionsSelected[key]
      );
      if (
        isNil(commodityId) ||
        !selectedCommodityIds.includes(commodityId?.toString())
      ) {
        return false;
      }
    }

    if (filteredAreas && !isEmpty(filteredAreas)) {
      const areaId = columnType?.typeVariety?.trial?.areaId;
      const filteredAreaIds = filteredAreas.map(area => area.id.toString());
      if (isNil(areaId) || !filteredAreaIds.includes(areaId?.toString())) {
        return false;
      }
    }
    if (filteredGrowers && !isEmpty(filteredGrowers)) {
      const growerId = columnType?.typeVariety?.trial?.growerId;
      const filteredGrowerIds = filteredGrowers.map(grower =>
        grower.id.toString()
      );
      if (
        isNil(growerId) ||
        !filteredGrowerIds.includes(growerId?.toString())
      ) {
        return false;
      }
    }
    if (filteredRegions && !isEmpty(filteredRegions)) {
      const regionId = columnType?.typeVariety?.trial?.regionId;
      const filteredRegionIds = filteredRegions.map(region =>
        region.id.toString()
      );
      if (
        isNil(regionId) ||
        !filteredRegionIds.includes(regionId?.toString())
      ) {
        return false;
      }
    }
    if (filteredShippers && !isEmpty(filteredShippers)) {
      const shipperId = columnType?.typeVariety?.trial?.shipperId;
      const filteredShipperIds = filteredShippers.map(shipper =>
        shipper.id.toString()
      );
      if (
        isNil(shipperId) ||
        !filteredShipperIds.includes(shipperId?.toString())
      ) {
        return false;
      }
    }

    // TODO filter evaluations and images of length 0

    return true;
  });

  return (
    <ReportCompareContext.Provider
      value={{
        mainVarieties,
        varieties,
        trialVarieties,
        filteredCombinedVarieties,
        fieldVarieties,
        displayGrower,
        setDisplayGrower,
        displayShipper,
        setDisplayShipper,
        displaySupplier,
        setDisplaySupplier,
        displayRanch,
        setDisplayRanch,
        filterOrganic,
        setFilterOrganic,
        filterGrowersLike,
        setFilterGrowersLike,
        filterConventional,
        setFilterConventional,
        excludeYears,
        setExcludeYears,
        searchByWetDate,
        setSearchByWetDate,
        commodityOptionsSelected,
        setCommodityOptionsSelected,
        supplierOptionsSelected,
        setSupplierOptionsSelected,
        filteredAreas,
        setFilteredAreas,
        filteredGrowers,
        setFilteredGrowers,
        filteredRegions,
        setFilteredRegions,
        filteredShippers,
        setFilteredShippers,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
      }}
    >
      {children}
    </ReportCompareContext.Provider>
  );
}
export function useReportCompareContext() {
  return React.useContext(ReportCompareContext);
}
