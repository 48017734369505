import { HarvestDateType, StatisticRecord } from "../types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { mean } from "lodash";

const dayGroupings: number = 3;

type ByMaturityDates = { [key: string]: number[] };

export default function DaysToHarvestTable({
  dateType,
  records,
  startDate,
  endDate,
}: {
  dateType: HarvestDateType;
  startDate: Dayjs;
  endDate: Dayjs;
  records: StatisticRecord[];
}) {
  const differenceInDays = (date1: Date, date2: Date) => {
    const differenceInTimes = date1.getTime() - date2.getTime();
    return differenceInTimes / (1000 * 3600 * 24);
  };

  const dateToKey = (date: Date) => `${date.getMonth()}-${date.getDay()}`;

  const groupDays = (byMaturityDates: ByMaturityDates) => {
    const rows = [];
    for (
      const initialDate = new Date(Number(startDate?.toDate()));
      initialDate < endDate?.toDate();
      initialDate.setDate(initialDate.getDate() + dayGroupings)
    ) {
      let grouping: number[] = [];
      const groupEndDate = new Date(Number(initialDate));
      groupEndDate.setDate(groupEndDate.getDate() + dayGroupings);
      for (
        const iDate = new Date(Number(initialDate));
        iDate < groupEndDate;
        iDate.setDate(iDate.getDate() + 1)
      ) {
        const key = dateToKey(iDate);
        if (byMaturityDates[key]) {
          grouping = grouping.concat(byMaturityDates[key]);
        }
      }
      const average = Math.round(mean(grouping));

      rows.push({
        startDate: initialDate.toDateString(),
        endDate: groupEndDate.toDateString(),
        average: average,
        total: grouping.length,
      });
    }
    return rows;
  };

  const compileData = (data: StatisticRecord[]) => {
    const byMaturityDates: ByMaturityDates = {};
    data
      .filter(record => (record.maturityDate ? true : false))
      .forEach(record => {
        const dateTypeKey =
          dateType === "wet_date" ? "wetDate" : "transplantDate";
        if (record.maturityDate && record[dateTypeKey]) {
          const maturityDate = new Date(record.maturityDate);
          // @ts-ignore
          const endDate = new Date(record[dateTypeKey]);
          const days = differenceInDays(maturityDate, endDate);
          const key = dateToKey(endDate);
          if (byMaturityDates[key] === undefined) {
            byMaturityDates[key] = [];
          }
          byMaturityDates[key].push(days);
        }
      });
    return groupDays(byMaturityDates);
  };

  const rows = compileData(records);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
          <TableCell># Trials</TableCell>
          <TableCell>Days To Harvest</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              {dayjs(row["startDate"]).format("MMM D, YYYY")}
            </TableCell>
            <TableCell>{dayjs(row["endDate"]).format("MMM D, YYYY")}</TableCell>
            <TableCell>{row["total"]}</TableCell>
            <TableCell>{row["average"]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
