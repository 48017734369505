import ErrorIcon from "@mui/icons-material/Error";
import LockIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import SessionApi from "../api/Session.api";
import { useSession } from "../contexts/Session.context";
import { Session } from "../types";

export default function PasswordReset() {
  const { token } = useParams();
  const theme = useTheme();
  const { setSession } = useSession();

  const navigate = useNavigate();

  const [password, setPassword] = React.useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] =
    React.useState<string>("");

  const handleSuccess = (data: Session) => {
    setSession && setSession(data);
    navigate("/");
  };

  const { isLoading, isError, mutate } = SessionApi.usePasswordReset({
    token: token || "",
    password,
    passwordConfirmation,
    handleSuccess,
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          width: "auto",
          display: "block", // Fix IE11 issue.
          marginLeft: theme.spacing(3),
          marginRight: theme.spacing(3),
          paddingTop: theme.spacing(10),
          [theme.breakpoints.up(400)]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: theme.spacing(2, 3, 3),
          }}
          elevation={2}
        >
          <Avatar
            sx={{
              margin: theme.spacing(1),
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <LockIcon />
          </Avatar>
          <Typography
            gutterBottom
            component="h1"
            variant="subtitle1"
            color="initial"
          >
            Reset Password
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%", // Fix IE11 issue.
              marginTop: theme.spacing(1),
              gap: "2rem",
            }}
          >
            {isError && (
              <Box>
                <ErrorIcon color="error" />
                <Typography color="error">{"error"}</Typography>
              </Box>
            )}
            <FormControl required fullWidth>
              <TextField
                name="password"
                label="Password"
                type="password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                }}
              />
            </FormControl>
            <FormControl required fullWidth>
              <TextField
                name="passwordConfirmation"
                label="Password Confirmation"
                type="password"
                value={passwordConfirmation}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPasswordConfirmation(event.target.value);
                }}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => mutate()}
              disabled={isLoading}
            >
              Sign in
            </Button>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  );
}
