import React from "react";

import RegionForm from "../components/RegionForm";

export default function RegionCreate() {
  return (
    <>
      <RegionForm />
    </>
  );
}
