import { EvaluableType, FieldVariety, TrialVariety } from "../../types";
import {
  Box,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import CollectionsIcon from "@mui/icons-material/Collections";
import ImageUpload from "./ImageUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import ImageApi from "../../api/Image.api";
import toast from "react-hot-toast";
import { HttpError } from "../../types/http-error";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EvaluationImages({
  typeVariety,
  evaluableType,
}: {
  typeVariety: FieldVariety | TrialVariety;
  evaluableType: EvaluableType;
}) {
  const [open, setOpen] = React.useState(false);
  const { data: images, refetch: refetchImages } = ImageApi.useList({
    parentId: typeVariety.id,
    parentType: evaluableType,
  });
  const { mutateAsync: deleteImage } = ImageApi.useDelete({
    parentId: typeVariety.id,
    parentType: evaluableType,
  });

  const handleDelete = (imageId: number) => async () => {
    try {
      await deleteImage(imageId);
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to delete image");
      }
    }
  };

  return (
    <>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ flex: 1 }}>
          <ImageUpload
            evaluableId={typeVariety.id}
            evaluableType={"field_variety"}
            refetchImages={refetchImages}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Button
            fullWidth
            variant={"contained"}
            color={"info"}
            onClick={() => setOpen(true)}
          >
            <CollectionsIcon />
          </Button>
        </Box>
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          {images ? (
            <ImageList
              // sx={{ width: 500, height: 450 }}
              cols={2}
              rowHeight={250}
            >
              {(images || []).map(item => (
                <ImageListItem key={item.id} cols={1} rows={1}>
                  <img
                    srcSet={item.url}
                    src={item.url}
                    alt={item.id.toString()}
                    loading="lazy"
                    style={{ objectFit: "cover" }}
                  />
                  <ImageListItemBar
                    title={dayjs(item.createdAt).format("YYYY-MM-DD")}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={"Delete Image"}
                        onClick={handleDelete(item.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <Typography variant={"h6"}>No images found</Typography>
          )}
          <Button
            fullWidth
            variant="contained"
            color="info"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}
