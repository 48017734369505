import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function PageHeader({
  title,
  children,
  half,
}: {
  title: string;
  children: JSX.Element;
  half?: number;
}) {
  const matches = useMediaQuery("(max-width: 400px)");
  return (
    <Grid container spacing={2}>
      <Grid item xs={half ? half : 8}>
        <Typography
          variant={matches ? "h4" : "h2"}
          gutterBottom
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={half ? 12 - half : 4}
        sx={{ display: "flex", flexGrow: 1 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
