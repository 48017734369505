import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

export default function IndexHeaderButton({
  handleCreate,
}: {
  handleCreate: () => void;
}) {
  return (
    <Button
      size={"large"}
      variant="contained"
      color="secondary"
      onClick={handleCreate}
    >
      <AddIcon />
    </Button>
  );
}
