import { Box, TextField } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import { Tag } from "../types";
import DeleteConfirmationWithHandler from "./DeleteConfirmationWithHandler";
import React from "react";
import { useLabelContext } from "../contexts/Label.context";
import { useDebounce } from "../utils";
import DeleteIcon from "@mui/icons-material/Delete";

export default function TagItem({ item }: { item: Tag }) {
  const { handleSaveTag, handleDeleteTag } = useLabelContext();

  const [name, setName] = React.useState<string>(item.name || "");

  const debouncedRequest = useDebounce(() => {
    handleSaveTag && handleSaveTag({ ...item, name: name });
  });

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    handleDeleteTag && handleDeleteTag(item.id);
  };

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "row", gap: "1rem" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ReorderIcon height={10} width={10} />
      </Box>
      <TextField
        value={name}
        fullWidth
        required={true}
        label="Tag Name"
        variant="outlined"
        onChange={event => {
          setName(event.target.value);
          debouncedRequest();
        }}
      />
      <DeleteConfirmationWithHandler handleDelete={handleDelete} id={item.id} />
    </Box>
  );
}

// A simplified version of our task for the user to drag around
export function DragPreview({ item }: { item?: Tag }) {
  if (!item) {
    return <></>;
  }
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", gap: "1rem", width: "70vw" }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ReorderIcon height={10} width={10} />
      </Box>
      <TextField
        value={item.name}
        fullWidth
        required={true}
        label="Tag Name"
        variant="outlined"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <DeleteIcon color={"primary"} />
      </Box>
    </Box>
  );
}
