import {ReportCompareProvider} from "../contexts/ReportCompare.context";
import {useParams} from "react-router-dom";
import React from "react";
import Filters from "../components/reports/Filters";
import ReportCompareHeader from "../components/reports/ReportCompareHeader";
import {Box, Button} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import ScatterPlotSingle from "../components/reports/ScatterPlotSingle";
import EvaluationColumns from "../components/reports/EvaluationColumns"


export default function ReportsSingle() {
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const { varietyId } = useParams<{ varietyId: string }>();

  const [heightOfScatterPlot, setHeightOfScatterPlot] = React.useState(220)
  React.useEffect(() => {
    function handleResize() {
      if (window.innerHeight < 400) {
        setHeightOfScatterPlot(220)
      } else {
        const height = Math.round(window.innerHeight / 1.7);
        setHeightOfScatterPlot(height)
      }
    }
    window.addEventListener('resize', handleResize)
  })

  const varietyIds = varietyId ? [varietyId] : []
  return (
    <ReportCompareProvider varietyIds={varietyIds}>
      <>
        <Filters showFilters={showFilters} setShowFilters={setShowFilters} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem"}}>
          <ReportCompareHeader />
        </Box>

        <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end", mt: "1rem"}}>
          <Button variant={"outlined"} color={"primary"} size={"large"} onClick={() => setShowFilters(true)}>
            <FilterListIcon />
          </Button>
        </Box>

        <Box sx={{ flex: 1, height: heightOfScatterPlot }}>
          <ScatterPlotSingle />
        </Box>
        <Box sx={{ flex: 1 }}>
          <EvaluationColumns reportType={"single"} />
        </Box>
      </>
    </ReportCompareProvider>
  )
}