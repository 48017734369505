import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import AreaApi from "../api/Area.api";
import { AreaInput } from "../types";
import { HttpError } from "../types/http-error";
import toast from "react-hot-toast";

export default function AreaForm({ area }: { area?: AreaInput }) {
  const navigate = useNavigate();
  const [name, setName] = React.useState<string>(area?.name || "");
  const { mutateAsync, isLoading } = AreaApi.useSave({
    ...area,
    name,
  });

  const onSave = async () => {
    try {
      const res = await mutateAsync();
      navigate(`/areas/${res.id}`);
    } catch (error) {
      console.error(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Area");
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {area?.id ? "Edit Area" : "Create Area"}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label={"Name"}
          fullWidth
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          component={Link}
          to="/areas"
          variant="contained"
          color="info"
          fullWidth
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={isLoading}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
