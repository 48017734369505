import React from "react";

import AreaForm from "../components/AreaForm";

export default function AreaCreate() {
  return (
    <>
      <AreaForm />
    </>
  );
}
