import { handleSimpleList } from "./handler";
import { useQuery } from "react-query";
import { CompanyCategory } from "../types";

const methods = {
  useList: () => {
    return useQuery({
      queryKey: ["company_categories"],
      queryFn: () =>
        handleSimpleList<CompanyCategory>({ url: "company_categories" }),
    });
  },
};

export default methods;
