import React from "react";
import { useNavigate } from "react-router-dom";

import PersonApi from "../api/Person.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import PageHeader from "../ui/PageHeader";
import { Person } from "../types";

export default function PeopleList() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader title={"People"} half={6}>
        <IndexHeaderButton
          handleCreate={() => {
            navigate("/people/create");
          }}
        />
      </PageHeader>
      <SimpleListName
        useList={PersonApi.useInfiniteList}
        useDelete={PersonApi.useDelete}
        baseUrl="people"
        headers={[
          {
            key: "name",
            label: "Name",
            getValue: (obj: Person) => `${obj?.firstName} ${obj?.lastName}`,
          },
          {
            key: "position",
            label: "Position",
            getValue: (obj: Person) => obj?.position,
          },
          {
            key: "regions",
            label: "Regions",
            getValue: (obj: Person) =>
              obj?.regions
                ? obj.regions?.map(region => region.name).join(", ")
                : "",
          },
          {
            key: "companies",
            label: "Companies",
            getValue: (obj: Person) =>
              obj.companies
                ? obj.companies?.map(company => company.name).join(", ")
                : "",
          },
        ]}
      />
    </>
  );
}
