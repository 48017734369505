import React from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import {
  APIProvider,
  Map,
  MapCameraChangedEvent,
  MapMouseEvent,
  Marker,
} from "@vis.gl/react-google-maps";
import { isEmpty } from "lodash";

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

const liveLocationOptions = {
  enableHighAccuracy: true,
  maximumAge: 0,
};

export default function FarmMap({
  lat,
  lng,
  zoom,
  setLat,
  setLng,
  setZoom,
}: {
  lat: string;
  lng: string;
  zoom: number;
  setLat: React.Dispatch<React.SetStateAction<string>>;
  setLng: React.Dispatch<React.SetStateAction<string>>;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [locked, setLocked] = React.useState<boolean>(
    !(isEmpty(lat) || isEmpty(lng))
  );
  const [error, setError] = React.useState<string>("");

  const refreshGeoLocation = React.useCallback(() => {
    console.log("refreshGeoLocation", locked);
    if (!locked) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            console.log("position", position);
            setLat(position.coords.latitude.toString());
            setLng(position.coords.longitude.toString());
          },
          error => setError("Not able to access GPS on device"),
          liveLocationOptions
        );
      } else {
        setError("Not able to access GPS on device");
      }
    } else {
      console.log("locked");
    }
  }, [locked, setLat, setLng, setError]);

  React.useEffect(() => {
    if (isEmpty(lat) || isEmpty(lng)) {
      refreshGeoLocation();
    }
  }, [lat, lng, refreshGeoLocation]);

  const toggleLock = () => {
    setLocked(l => !l);
  };

  const handleClick = (ev: MapMouseEvent) => {
    if (!locked && ev.detail.latLng?.lng && ev.detail.latLng?.lat) {
      setLat(ev.detail.latLng.lat.toString());
      setLng(ev.detail.latLng.lng.toString());
    }
  };

  const onCenterChanged = (ev: MapCameraChangedEvent) => {
    if (!locked) {
      setLat(ev.detail.center.lat.toString());
      setLng(ev.detail.center.lng.toString());
    }
  };

  const onZoomChange = (ev: MapCameraChangedEvent) => {
    if (!locked) {
      setZoom(ev.detail.zoom);
    }
  };

  return (
    <>
      <Grid item xs={4} md={3}>
        <TextField
          fullWidth
          label={"Latitude"}
          variant="outlined"
          value={lat}
          onChange={event => setLat(event.target.value)}
          disabled={locked}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          fullWidth
          label={"Longitude"}
          variant="outlined"
          value={lng}
          onChange={event => setLng(event.target.value)}
          disabled={locked}
        />
      </Grid>
      <Grid item xs={4} md={2}>
        <Button
          size={"large"}
          variant={"contained"}
          color={"info"}
          fullWidth
          onClick={refreshGeoLocation}
        >
          Live
        </Button>
      </Grid>
      <Grid item xs={6} md={2}>
        <Button
          size={"large"}
          variant={"contained"}
          color={"info"}
          fullWidth
          onClick={toggleLock}
        >
          {locked ? "Unlock" : "Lock"}
        </Button>
      </Grid>
      <Grid item xs={6} md={2}>
        <a
          href={`http://maps.google.com/?q=${lat},${lng}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button size="large" variant="contained" color="info" fullWidth>
            Directions
          </Button>
        </a>
      </Grid>
      <Grid item xs={12}>
        {lat && lng ? (
          <APIProvider apiKey={googleMapsApiKey} libraries={["marker"]}>
            <Map
              style={{ width: "auto", height: "30rem" }}
              center={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
              zoom={zoom}
              gestureHandling={"greedy"}
              disableDefaultUI={false}
              onClick={handleClick}
              onZoomChanged={onZoomChange}
              onCenterChanged={onCenterChanged}
              mapTypeId={"satellite"} //terrain, hybrid, roadmap, satellite
            >
              {/* Child components, such as markers, info windows, etc. */}
              <Marker
                position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                clickable={true}
                onClick={() => alert("marker was clicked!")}
                title={"clickable google.maps.Marker"}
              />
            </Map>
          </APIProvider>
        ) : (
          <Typography gutterBottom variant="h6">
            Map currently not set
          </Typography>
        )}
      </Grid>
    </>
  );
}
