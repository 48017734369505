import { createTheme } from "@mui/material/styles";
// import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: "rgba(0, 0, 0, 1)",
      main: "rgba(3, 67, 144, 1)",
      dark: "rgba(208, 2, 27, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      light: "rgba(208, 2, 27, 1)",
      main: "rgba(155, 155, 155, 1)",
      dark: "rgba(208, 2, 27, 1)",
      contrastText: "#fff",
    },
    info: {
      main: "#c2c2c2",
    },
  },
  // content: {
  //   marginTop: 20
  // },
  // addButton: {
  //   marginTop: 25
  // },
  // headerText: {
  //   marginTop: 20,
  //   paddingBottom: 10
  // },
  // searchBar: {
  //   marginTop: 25
  // },
  // linkStyle: {
  //   textDecoration: "none",
  //   color: "black"
  // },
  // linkTypography: {
  //   color: "black"
  // }
});

export default theme;
