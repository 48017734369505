import { useParams } from "react-router-dom";
import TrialApi from "../api/Trial.api";
import { Grid } from "@mui/material";
import TrialForm from "../components/trials/TrialForm";
import TrialVarietyApi from "../api/TrialVariety.api";

export default function TrialShow() {
  const { id } = useParams<{ id: string }>();
  const { data: trial } = TrialApi.useDetail(id);
  const { data: trialVarieties } = TrialVarietyApi.useList(id);
  return (
    <Grid container spacing={2}>
      {trial && trialVarieties && (
        <TrialForm trial={trial} trialVarieties={trialVarieties} />
      )}
    </Grid>
  );
}
