import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";

export default function DeleteConfirmationWithHandler({
  id,
  handleDelete,
  question,
}: {
  id: number;
  handleDelete: (event: React.MouseEvent<HTMLElement>) => void;
  question?: string;
}) {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleConfirm = async (event: React.MouseEvent<HTMLElement>) => {
    await handleDelete(event);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button size="small" aria-label="Delete" onClick={() => setOpen(true)}>
        <DeleteIcon />
      </Button>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {question || "Are you sure you want to delete the following?"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
          <Button onClick={() => setOpen(false)}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
